import styled from 'styled-components';
import { BiPlusMedical } from "react-icons/bi";
import img from 'assets/img/DESK_IMG.png';
import mobile from 'assets/img/MOBILE_IMG.png';

export const Container = styled.div`
    display:flex;
    flex-direction:column;
`;

export const Background = styled.div`
    background-image: url(${img});
    background-repeat:no-repeat;
    background-size:62% 95%;
    background-position:right top;

    @media(max-width:900px){
       background-size:65% 59%; 
       background-position:47vw top;
    }

    @media(max-width:600px){
        background-image: url(${mobile});
        background-size: 59% 41%;        
    }
`;

export const Main = styled.main`
    width: 75%;
    max-width:1340px;
    margin: 2rem auto 10rem;

    @media(max-width:900px){
        width:80%;
        margin-bottom:0;
    }

    @media(max-width:600px){
        width:90%;
    }

    @media(max-width:375px){
        margin-top:1rem;
    }
`;

export const Center = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;

    @media(max-width:900px){
        flex-direction:column;
        align-items:flex-start;
    }
`;

export const ButtonDiv = styled.div`
    margin-top:4rem;
    text-align: center;

    @media(max-width:900px){
       text-align: left;
    }

    @media(max-width:600px){
        text-align: center;
    }
`;

export const SectionList = styled.section`
    display:flex;
    flex-direction:column;
    height:100%;
    width:75%;
    margin:0 auto;
    color:var(--color-roxo);

    @media(max-width:900px){
        padding-top:60px;
    }
    
    @media(max-width:600px){
        width:92%;
    }

`;
export const IconBiPlusMedical = styled(BiPlusMedical)`
    width:6.8rem;
    height:6.8rem;
    color:var(--primaryTextColor);
    margin-bottom:2.9rem;

    @media(max-width:600px){
        width:5.5rem;
        height:5.5rem;
        margin-bottom:1.9rem;
    }
`;

export const Titulo = styled.div`
    display:flex;
    align-items:flex-end;
    margin-bottom:2.1rem;

    >p{
        color:var(--primaryTextColor);
        margin-left:-1.9rem;
        font-size:2rem;
        font-family:'Gotham Bold';

        @media(max-width:600px){
            font-size:1.5rem;
            margin-left: -1rem;
        }
    }
`;

export const Footer = styled.footer`
    display:flex;
    margin-bottom:1.5rem;
    padding-top: 6rem;
    flex-direction:column;
    align-items:center;
    text-align:center;
    font-family:'Gotham Book';

    p, a{
        color:var( --color-cinza-input);
    }
`;

export const WapperFundacred = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:3rem;

    >p{
        font-size:1rem;
        margin-top:-0.4rem;
        margin-right:0.5rem;
        color:var( --color-cinza-input);
    }

    >img{
        margin-left:0.3rem;
    }
`;

