import React from 'react';
import Button from '@material-ui/core/Button';

function ButtonGA(props) {
    
    const onClick = () => {
        if(props.onClick) {
            props.onClick();
        }
    }

    const childProps = {...props, onClick}

    return ( 
        <Button {...childProps}>{props.children}</Button>
    )
}

export default ButtonGA;
