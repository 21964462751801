import React, { useCallback, useEffect, useRef, useState } from 'react';
import video from "../../assets/video/video_maisacesso.mp4";

import {
    Container,
    Video,
    IconBiPlusMedicalUm,
    IconBiPlusMedicalDois,
    IconBiPlusMedicalTres,
    IconBiPlusMedicalQuatro,
    IconAiOutlinePlayCircle
} from './styles';

const ColumnVideo: React.FC = () => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const videoPlay = useRef<HTMLVideoElement>(null);

    function handlePlay() {
        setIsPlaying(true);
        if (videoPlay.current) {
            videoPlay.current.play();
        }
    }

    return (
        <Container>
            <Video>
                <video
                    src={video}
                    ref={videoPlay}
                    onPause={() => setIsPlaying(false)}
                    controls={isPlaying}
                >
                </video>
                <IconBiPlusMedicalUm />
                <IconBiPlusMedicalDois />
                <IconBiPlusMedicalTres />
                <IconBiPlusMedicalQuatro />
                <a href={void (0)} onClick={handlePlay} >
                    {isPlaying ? <></> : <IconAiOutlinePlayCircle />}
                </a>
            </Video>
        </Container>
    )
}

export default ColumnVideo;