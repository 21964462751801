import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import '../assets/css/EtapasSolicitacao.css';

import IconPlus from '../assets/img/icon-plus.png';
import IconLogin from '../assets/img/icon-login.png';
import IconCurso from '../assets/img/icon-escolher-curso.png';
import IconDocumentos from '../assets/img/icon-documentos.png';
import IconAnalise from '../assets/img/icon-analise.png';

function EtapasSolicitacao() {
	return (
		<section id="etapas_solicitacao">
			<Container>
				<Grid container justify="center">
					<Grid item xs={10} md={12}>
						<h2 className="txt-primary">Etapas da solicitação</h2>
					</Grid>

					<Grid container item xs={12} lg={11} justify="space-between">
						<Grid item xs={2} md={2} className="item-solicitacao">
							<img className="fluid" src={IconLogin} />
							<span>Criar cadastro</span>
						</Grid>
						<Grid item xs={1} md={1} className="plus-ind">
							<img className="fluid" src={IconPlus} />
						</Grid>

						<Grid item xs={2} md={2} className="item-solicitacao">
							<img className="fluid" src={IconCurso} />
							<span>Selecionar instituição e curso</span>
						</Grid>
						<Grid item xs={1} md={1} className="plus-ind">
							<img className="fluid" src={IconPlus} />
						</Grid>

						<Grid item xs={2} md={2} className="item-solicitacao">
							<img className="fluid" src={IconDocumentos} />
							<span>Enviar foto dos documentos</span>
						</Grid>
						<Grid item xs={1} md={1} className="plus-ind">
							<img className="fluid" src={IconPlus} />
						</Grid>

						<Grid item xs={2} md={2} className="item-solicitacao">
							<img className="fluid" src={IconAnalise} />
							<span>Aguardar a análise</span>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
}

export default EtapasSolicitacao;