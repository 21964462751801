import React from 'react';
import { Link } from 'react-scroll';

function LinkSocialGA(props) {
  const href = props.href ? props.href : '';
  const target = props.target ? props.target: '';

  const onClick = () => {
    if(props.onClick) {
      props.onClick();
    }
  
    if(target!==''){
        window.open(href, target)
    }
  };

  const childProps = {...props, onClick}

  return (
    <Link {...childProps}>{props.children}</Link>
  )
}

export default LinkSocialGA;
