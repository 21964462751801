import React from "react";
import { tiposPeriodo } from "../../utils/simulador-service";

function InputDuracaoCredito(props) {
  const className = props.className;
  const max =
    props.tipoPeriodo === tiposPeriodo.semestral ||
    props.tipoPeriodo === tiposPeriodo.quadrimestral
      ? 20
      : 10;
  const value =
    props.tipoPeriodo === tiposPeriodo.semestral ||
    props.tipoPeriodo === tiposPeriodo.quadrimestral
      ? props.duracaoDoCredito
      : props.duracaoDoCredito / 2;

  return (
    <input
      type="number"
      className={className}
      value={value}
      readOnly
      min="1"
      max={max}
    ></input>
  );
}

export default InputDuracaoCredito;
