import styled from 'styled-components';

export const Container = styled.div`    
    
    > p{
        color:var(--secondaryTextColor);
        font-size:2.8rem;
        margin-bottom:1.7rem ;
        font-family: 'Gotham Bold';
        line-height:1.1;

        @media(max-width:900px){
            width: 80%;
            margin-top: 2rem;
        }

        @media(max-width:600px){
            font-size:2.4rem;
        }
    }
`;