import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ButtonGA from './UI/ButtonGA';
import '../assets/css/Compare.css';
import DefaultContext from '../themes/DefaultContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Scroll from 'react-scroll';
import LogoBrancoDesk from '../assets/img/logo-branco.svg';
import LogoBrancoMob from '../assets/img/logo-branco-mob.png';

function Compare(props) {
  const targetRef = React.useRef();
  const [values, setValues] = React.useState({
    colAzul: { width: '0' },
  });

  const larguraColunaAzul = () => {
    if (targetRef.current) {
      setValues({
        colAzul: { width: targetRef.current.offsetWidth - 1 + 'px' },
      });
    }
  };
  React.useLayoutEffect(() => {
    larguraColunaAzul();
  }, []);

  let movement_timer = null;
  const RESET_TIMEOUT = 100;
  window.addEventListener('resize', () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(larguraColunaAzul, RESET_TIMEOUT);
  });

  return (
    <section id="compare" className="compare">
      <Container maxWidth="lg">
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={8}>
            <h2>
              Precisa de mais para <br />
              se convencer?
            </h2>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={8}>
            <p className="text-center txt-compare">
              Confira nossos diferenciais comparados aos principais serviços do segmento:
            </p>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={8}>
            <Card className="tabela-compare">
              <CardContent>
                <Grid cotainer className="coluna-lg" direction="column" justify="center" alignItems="center">
                  <Grid item>&nbsp;</Grid>
                  <Grid item>
                    <p>Exige fiador?</p>
                  </Grid>
                  <Grid item>
                    <p>Sem juros?</p>
                  </Grid>
                  <Grid item>
                    <p>Faz análise de crédito?</p>
                  </Grid>
                  <Grid item>
                    <p>Exige nota do ENEM?</p>
                  </Grid>
                  <Grid item>
                    <p>Exige conta bancária?</p>
                  </Grid>
                </Grid>
                <Grid
                  cotainer
                  className="coluna-azul coluna-md"
                  direction="column"
                  justify="center"
                  alignItems="center"
                  ref={targetRef}
                >
                  <div id="borda-superior" style={values.colAzul}></div>
                  <Grid item>
                    {isWidthUp('sm', props.width) ? (
                      <img src={LogoBrancoDesk} alt="Mais Acesso" />
                    ) : (
                      <img src={LogoBrancoMob} alt="Mais Acesso" />
                    )}
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faTimes} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faTimes} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faTimes} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faTimes} />
                    </p>
                  </Grid>
                  <div id="borda-inferior" style={values.colAzul}></div>
                </Grid>
                <Grid
                  cotainer
                  className="coluna-md coluna-cinza"
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <p className="tit-compare">P-Fies</p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faTimes} />
                      De 1,9% até 2,5% ao mês
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  cotainer
                  className="coluna-md coluna-cinza"
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <p className="tit-compare">Crédito Privado</p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faTimes} />
                      até 2,91% ao mês
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <FontAwesomeIcon className="icone-compare" icon={faCheck} />
                    </p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={6} md={4} className="text-center-mob">
            <ButtonGA
              activeClass="active"
              type="submit"
              className="btn btn-default"
              to="simulacao"
              spy={true}
              smooth={true}
              duration={500}
              onClick={() => {
                Scroll.animateScroll.scrollToTop();
              }}
            >
              Simular crédito
            </ButtonGA>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="p-relative text-center-mob">
            <DefaultContext.Consumer>
              {({ redirectToWebapp }) => (
                <ButtonGA id="button-solicitar-credito" className="btn btn-outline" onClick={redirectToWebapp}>
                  Solicitar crédito
                </ButtonGA>
              )}
            </DefaultContext.Consumer>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withWidth()(Compare);
