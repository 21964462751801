import React from 'react';
import Transition from 'react-transition-group/Transition';
import '../assets/css/MenuMobile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button';
import DefaultContext from '../themes/DefaultContext';
import ButtonGA from './UI/ButtonGA';
import MenuLinks from './MenuLinks';

function MenuMobile(props) {

	const duration = 300;

	const defaultStyle = {
		transition: `opacity 300ms ease-in-out`,
		opacity: 0,
	}

	const transitionStyles = {
		entering: { opacity: 0, display: 'none' },
		entered: { opacity: 1, display: 'block' },
		exited: { opacity: 0, display: 'none' },
	};

	return (

		<Transition
			{...props}
			timeout={duration}>
			{(state) => (
				<div style={{
					...defaultStyle,
					...transitionStyles[state]
				}}>
					<section className="menu-mobile">
						<Button className="btn-close" onClick={props.onClose}><FontAwesomeIcon icon={faTimes} /></Button>
						<h3>Menu</h3>
						<nav>
							<MenuLinks onMenuItemClicked={props.onClose} />
						</nav>
						
						<DefaultContext.Consumer>
							{({ redirectToWebapp }) => (
								<ButtonGA className="btn btn-outline btn-borderless"
									onClick={redirectToWebapp}>
									Entrar
								</ButtonGA>
							)}
						</DefaultContext.Consumer>
					</section>
				</div>
			)}
		</Transition>
	);
}

export default MenuMobile;