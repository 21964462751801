import React, { Fragment, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import LazyLoad from 'react-lazyload';
import DefaultContext from '../themes/DefaultContext';

function BlocoFormatura() {
	const context = useContext(DefaultContext);

	return (
		<Fragment>
			<Grid container>
				<Grid item xs={context.theme.name === 'credneo' ? 6 : 8} sm={8} md={9}>
					<Card className="card-como-funciona">
						<CardContent>
							<h4>Enquanto você<br />estuda</h4>
							<p>{context.theme.comoFunciona.durante.text}</p>
						</CardContent>
					</Card>
				</Grid>
			</Grid>

			<Grid container className="txt-como-funciona">
				<Grid item xs={6} sm={6} md={9}>
					{context.theme.comoFunciona.durante.items.map((item, index) => {
						return (
							<Grid container key={index}>
								<Grid item xs={3}>
									<LazyLoad once>
										<img src={item.icon} alt={item.text} />
									</LazyLoad>
								</Grid>
								<Grid item xs={9}>
									<p>{item.text}</p>
								</Grid>
							</Grid>
						);
					})}

					{context.theme.helpPeriodo && 
						<p className="txt-credito">{context.theme.helpPeriodo}</p>
					}
					
				</Grid>
			</Grid>
		</Fragment>
	);
}

export default BlocoFormatura;