import styled from 'styled-components'
import { FaAngleDown } from "react-icons/fa";


export const Container = styled.div`
    box-shadow:2px 2px 10px var(--color-cinza);
    margin-bottom:0.7rem;
    
    @media(max-width:600px){
        margin-bottom:0.625rem;
    }
`;

export const AccordionSection = styled.div`
    padding:1rem 2rem;

    @media(max-width:600px){
        padding: 1rem 1.8rem 1rem 1.2rem;
    }

    >label{
        display:flex;
        font-family:'Gotham Bold';
        cursor: pointer;

        @media(max-width:600px){
            padding-bottom: 2rem;
        }
    }
    
    >input{
        display:none;

        &:checked{
            ~ span{
                height:auto;
                max-height:19.1rem;        
                margin:1rem auto;
            }
            ~ label > svg{
                transform: rotate(180deg);
            }
        }
    }

    >span{
        display:block;
        overflow: hidden;
        max-height:0;
        transition:all .3s ease-in-out;
        background:var(--color-white);
        font-family:'Gotham Book';

        @media(max-width:600px){
            padding-left: 1.5rem;
        }
    }

`;

export const IconFaAngleDown = styled(FaAngleDown)`
    height:1.5rem;
    width:1.5rem;
    margin-right:0.8rem;
    transition:all .3s ease-in-out;
`;
