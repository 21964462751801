import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import DefaultContext from '../themes/DefaultContext';

class BlocoCurso extends React.Component {
	static contextType = DefaultContext;

	render() {
		return (
			<Fragment>
				<Grid container justify="flex-end">
					<Grid item xs={8} sm={8} md={9}>
						<Card className="card-como-funciona">
							<CardContent>
								<h4>{this.context.theme.comoFunciona.depois.title}</h4>
								<p>{this.context.theme.comoFunciona.depois.text}</p>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				<Grid container justify="flex-end" className="txt-como-funciona">
					<Grid item xs={6} sm={6} md={9}>
						{this.context.theme.comoFunciona.depois.items.map((item, index) => {
							return (
								<Grid container key={index}>
									<Grid item xs={3}>
										<img src={item.icon} alt={item.text} />
									</Grid>
									<Grid item xs={9}>
										<p>{item.text}</p>
									</Grid>
								</Grid>
							);
						})}

						<p className="txt-credito">
							**Após a data prevista para conclusão do curso ou fim do vínculo com a instituição. Consulte o regulamento.
						</p>						
					</Grid>
				</Grid>
			</Fragment>
		);
	}
}

export default BlocoCurso;