import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const ThumbSlider = (props) => {
    return (
        <span {...props}>
            <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon"></FontAwesomeIcon>
            <span className="outer">
                <span className="inner" />
            </span>
            <FontAwesomeIcon icon={faArrowRight} className="arrow-icon"></FontAwesomeIcon>
        </span>
    );
};

export default ThumbSlider;