import styled from 'styled-components';

export const ButtonStyled = styled.button` 
    border: none;
    border-radius: 5rem;
    background:var( --color-roxo) ;
    font-family:'Gotham Bold';
    color: var(--btnDefaultTextColor);
    cursor:pointer;
    outline: none;
    width:322px;
    max-width:100%;
    height:45px;
    
    @media(max-width:600px){
        width: 100%;
    }

    &:hover{
        cursor:pointer;
        background:var( --primaryTextColor) ;
    }

    &:focus{
        outline:thin dotted;
        outline:0px auto;
        outline-offset:0;
    }
`;
