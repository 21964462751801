import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:35%;
    
    @media(max-width:900px){
        width:90%;
        display:flex;
    }
    
`;

export const SubText = styled.span`
    font-family: 'Gotham Book';
    color:var(--color-blue);
    line-height: 1.3;
`;
