import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
    root: {},
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    option: {
        fontSize: 13,
        height: 48,
        fontFamily: "Gotham Bold",
        '&:nth-child( odd )': {
            backgroundColor: '#F0F5FC',
        },
        color: '#455266',
        '& .secondary': {
            textTransform: 'capitalize',
            color: '#99A8BF',
            paddingLeft: 10
        }
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
}));

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >

        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

const Input = (props) => <components.Input {...props} autoComplete="new-password" />;

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            margin="dense"
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                }
            }}
            {...TextFieldProps}
        />
    );
}

function Option(props) {
    let secondaryText = null;
    if (props.children.secondary) {
        secondaryText = <div className="secondary">{props.children.secondary.toLowerCase()}</div>;
    }

    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            className={props.selectProps.classes.option}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children.label}{secondaryText}
        </MenuItem>
    );
}

function SingleValue(props) {
    return (
        <Typography {...props.innerProps}>
            {props.children.label}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const IndicatorSeparator = () => {
    return null;
};

function DropdownIndicator() {
    return <ArrowDropDown />;
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const customComponents = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    SingleValue,
    ValueContainer,
    IndicatorSeparator,
    DropdownIndicator,
    Input
};

export default function AutocompleteSelect(props) {
    const [helperText, setHelperText] = useState(props.autoFocus ? props.helperText : null);
    const [single, setSingle] = useState(null);
    const classes = useStyles();
    const theme = useTheme();

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    useEffect(() => {
        if (!props.inputValue) {
            setSingle(null);
        } else {
            const val = props.items.find(item => item.label === props.inputValue);
            setSingle(val);
        }
    }, [props.inputValue, props.items]);

    const changedHandler = (value) => {
        setSingle(value);
        props.onChange(value);
    }

    const menuOpenHandler = () => {
        setHelperText(props.helperText);
    }

    const menuCloseHandler = () => {
        setHelperText('');
    }

    return (
        <div className={classes.root}>
            <Select
                classes={classes}
                styles={selectStyles}
                getOptionLabel={option => option}
                getOptionValue={option => option.label}
                TextFieldProps={{
                    label: (!props.disabled && props.items.length <= 0 ? props.emptyText : props.label),
                    helperText: props.error ? props.errorText : helperText,
                    autoComplete: 'off',
                    InputLabelProps: {
                        shrink: (single || helperText) ? true : false
                    },
                    disabled: (props.disabled || props.items.length <= 0)
                }}
                placeholder=''
                components={customComponents}
                options={props.items}
                isDisabled={(props.disabled || props.items.length <= 0)}
                autoFocus={props.autoFocus}
                defaultMenuIsOpen={props.autoFocus}
                onMenuOpen={menuOpenHandler}
                onMenuClose={menuCloseHandler}
                value={single}
                onChange={changedHandler}
            />
        </div>
    );
}
