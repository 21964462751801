/*
* Ref: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Tabs/TabScrollButton.js
*/
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

const borderWidth = '6px';

export const styles = {
    root: {
        width: 20,
        height: 20,
    },
    circle: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        background: '#fff',
        position: 'absolute'
    },
    arrow: {
        width: 0,
        height: 0,
        zIndex: 1,
        borderTop: borderWidth + ' solid transparent',
        borderBottom: borderWidth + ' solid transparent',
        '&.left': {
            borderLeft: borderWidth + ' solid #757575'
        },
        '&.right': {
            borderRight: borderWidth + ' solid #757575'
        }
    },
};

const TabScrollButton = React.forwardRef(function TabScrollButton(props, ref) {
    const { classes, className: classNameProp, direction, visible, ...other } = props;
    
    const className = clsx(classes.root, classNameProp);
    const classNameArrow = clsx(classes.arrow, direction === 'right' ? 'left' : 'right');

    if (!visible) {
        return <div className={className} />;
    }

    return (
        <ButtonBase
            component="div"
            className={className}
            ref={ref}
            role={null}
            tabIndex={null}
            {...other}>

            <span className={classes.circle} />
            <span className={classNameArrow} />
        </ButtonBase>
    );
});

TabScrollButton.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Which direction should the button indicate?
     */
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
    /**
     * Should the button be present or just consume space.
     */
    visible: PropTypes.bool.isRequired,
};

export default withStyles(styles, { name: 'PrivateTabScrollButton' })(TabScrollButton);