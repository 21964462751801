import React from 'react';
import LinkGA from './UI/LinkGA';
import Link from "@material-ui/core/Link";

function MenuLinks(props) {
	return (
		<>
			<LinkGA href={"/#como_funciona"} to="como_funciona" spy={true} smooth={true} duration={500} onClick={props.onMenuItemClicked}>Como funciona</LinkGA>
			<LinkGA href={"/#etapas_solicitacao"} to="etapas_solicitacao" spy={true} smooth={true} duration={500} onClick={props.onMenuItemClicked}>Etapas da solicitação</LinkGA>
			<LinkGA href={"/#compare"} to="compare" spy={true} smooth={true} duration={500} onClick={props.onMenuItemClicked}>Compare condições</LinkGA>
			<LinkGA href={"/#quem_somos"} to="quem_somos" spy={true} smooth={true} duration={500} onClick={props.onMenuItemClicked}>Quem Somos</LinkGA>
			<Link target="_blank" href="https://blog.fundacred.org.br/">Blog</Link>
		</>
	);
}

export default MenuLinks;
