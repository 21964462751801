import styled from 'styled-components';
export const Container = styled.div`
    padding-top: 0.8rem;

    > input{
    display: flex;
    width: 100%;
    height: 3rem;
    border:none;
    border-bottom: 1px solid var(--color-cinza);
    font-size: 1.2rem ; 
   
    padding-bottom:  -1.6rem;
    &:focus{
        width: 100%;
        height: 3rem;
        border:none;
        border-bottom: 1px solid var(--color-cinza);
        font-size: 1.2rem ; 
        padding-bottom:  -1.6rem;
        outline: none;
        background:white;
    } 
    &:hover{
        cursor:pointer;
    }

    
}
`;