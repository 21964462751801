import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CurrencyInputSlider from './UI/forms/CurrencyInputSlider';
import CombosSimulacao from './UI/forms/CombosSimulacao';
import InputEmail from './UI/forms/InputEmail';
import ButtonGA from './UI/ButtonGA';
import ResultadoSimulacao from './../modals/ResultadoSimulacao';
import Tooltip from '@material-ui/core/Tooltip';
import DefaultContext from '../themes/DefaultContext';
import * as simuladorService from '../utils/simulador-service';
import '../assets/css/FormSimulacao.css';

class FormSimulacao extends Component {
  static contextType = DefaultContext;

  state = {
    firstSubmit: false,
    openModalResultado: false,
    resultadoSimulacao: null,
    newsletter: false,
    paramsSimulacao: {
      produto: 'credIes',
      percentualSolicitado: 50,
      duracaoDoCredito: 8,
      bolsa: null,
      codBolsa: null,
      curso: null,
      grau: null,
      mensalidade_curso: null,
      email: null,
      newsletter: 0,
      tipoPeriodo: null,
    },
    cobertura: null,
    isInvalidForm: true,
    simulacaoForm: {
      estado: {
        value: null,
        valid: false,
      },
      instituicao: {
        value: null,
        tipo: null,
        tipoPeriodo: null,
        valid: false,
      },
      campus: {
        value: null,
        valid: true,
      },
      curso: {
        value: null,
        valid: false,
      },
    },
    mensalidade: {
      value: '',
      valid: false,
    },
    email: {
      value: '',
      valid: false,
    },
  };

  getCobertura = async (instituicaoId, cursoId) => {
    simuladorService.getCobertura(instituicaoId, cursoId).then((response) => {
      const paramsSimulacao = { ...this.state.paramsSimulacao };
      if (response.data.tipo === 'O') {
        paramsSimulacao.percentualSolicitado = response.data.valores[0].valor;
      } else if (response.data.tipo === 'V') {
        paramsSimulacao.percentualSolicitado = response.data.valorMaximo;
      } else {
        paramsSimulacao.percentualSolicitado = response.data.valores[0].valor;
      }

      this.setState({
        cobertura: response.data,
        paramsSimulacao: paramsSimulacao,
      });
    });
  };

  handleSubmit = async (event) => {
    this.setState({ firstSubmit: true });
    event.preventDefault();
    event.stopPropagation();

    if (
      this.state.isInvalidForm ||
      !this.state.mensalidade.valid ||
      !this.state.email.valid ||
      !this.state.newsletter
    ) {
      return false;
    }

    const resultadoSimulacao = await this.calculaResultadoSimulacao();
    this.setState({ resultadoSimulacao, openModalResultado: true });
  };

  handleRecalcular = async () => {
    const resultadoSimulacao = await simuladorService.montarResultadoSimulador(this.state.paramsSimulacao);
    this.setState({ resultadoSimulacao, openModalResultado: true });
  };

  getDuracaoCredito = (duracao, tPeriodo) => {
    if (duracao)
      return simuladorService.tiposPeriodo.quadrimestral === tPeriodo
        ? Math.floor(duracao * 1.5) // numero de periodos x (6 / 4)
        : duracao;
    if (simuladorService.tiposPeriodo.unico === tPeriodo) return 4;
    return 8;
  };

  calculaResultadoSimulacao = async () => {
    const { simulacaoForm } = this.state;
    const paramsSimulacao = {
      produto: 'credIes',
      percentualSolicitado: this.state.paramsSimulacao.percentualSolicitado,
      duracaoDoCredito: this.getDuracaoCredito(
        simulacaoForm.curso.duracaoCredito,
        simulacaoForm.instituicao.tipoPeriodo,
      ),
	curso: simulacaoForm.curso.value,
      bolsa: simulacaoForm.instituicao.value,
      codBolsa: simulacaoForm.curso.codBolsa,
      grau: simuladorService.isPos(this.props.niveis) ? 'PÓS' : this.props.niveis[0],
      mensalidade_curso: this.state.mensalidade.value,
      email: this.state.email.value,
      newsletter: this.state.newsletter ? 1 : 0,
      tipoPeriodo: simulacaoForm.instituicao.tipoPeriodo,
    };
    this.setState({ paramsSimulacao });
    return await simuladorService.montarResultadoSimulador(paramsSimulacao);
  };

  mensalidadeChangedHandler = (value, valid) => {
    this.setState({ mensalidade: { value, valid } });
  };

  emailChangedHandler = (value) => {
    this.setState({ email: value });
  };

  newsletterChangedHandler = (event) => {
    this.setState({ newsletter: event.target.checked });
  };

  closeResultado = () => {
    this.setState({ openModalResultado: false });
  };

  optionsSimulacaoHandler = (options) => {
    const simulacao = { ...this.state.simulacaoForm, ...options };
    this.setState({ simulacaoForm: simulacao });
    this.getCobertura(simulacao.instituicao.value, simulacao.curso.value);
  };

  invalidSimulacaoHandler = (isInvalid) => {
    this.setState({ isInvalidForm: isInvalid });
  };

  render() {
    const { firstSubmit, mensalidade } = this.state;
    const { theme, redirectToWebapp } = this.context;

    return (
      <section className="bloco-form-simulacao">
        {navigator.userAgent !== 'ReactSnap' && (
          <>
            <Card className="card-form-simulacao">
              <CardContent>
                <form onSubmit={this.handleSubmit} autoComplete="off" noValidate>
                  <CombosSimulacao
                    niveis={this.props.niveis}
                    paramsBolsa={this.props.paramsBolsa}
                    shouldValidate={firstSubmit}
                    onOptionsSelected={this.optionsSimulacaoHandler}
                    onInvalid={this.invalidSimulacaoHandler}
                  />

                  <FormGroup>
                    <CurrencyInputSlider
                      label="Valor da mensalidade"
                      value={mensalidade.value}
                      minValue={100}
                      error={firstSubmit && !mensalidade.valid}
                      changed={this.mensalidadeChangedHandler}
                    />
                  </FormGroup>

                  <FormGroup>
                    <InputEmail shouldValidate={firstSubmit} onChange={this.emailChangedHandler} />
                  </FormGroup>
                  <FormGroup className={this.state.firstSubmit && !this.state.newsletter ? 'invalid-checkbox' : ''}>
                    <FormControlLabel
                      className="checkbox-news"
                      label="Aceito receber novidades e informações no e-mail cadastrado."
                      control={
                        <Checkbox
                          checked={this.state.newsletter}
                          value={true}
                          onChange={this.newsletterChangedHandler}
                        />
                      }
                    />
                  </FormGroup>

                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    placement="top"
                    title="Os dados são necessários para que possamos oferecer a melhor experiência possível de simulação"
                  >
                    <span className="porque-pedimos no-mobile">Por que pedimos esses dados?</span>
                  </Tooltip>

                  <p className="lgpd-policies">
                    A Fundacred se preocupa com o uso de seus dados pessoais. Ao fornecer seus dados você concorda com o
                    nosso{' '}
                    <a
                      className="lgpd-policies-link"
                      href="https://fundacred.org.br/estudante-web/aviso-de-privacidade"
                      target="_blank"
                    >
                      Aviso de Privacidade
                    </a>{' '}
                    e{' '}
                    <a
                      className="lgpd-policies-link"
                      href="https://portal.fundacred.org.br/estudante-web/politica-cookies/"
                      target="_blank"
                    >
                      Política de Cookies
                    </a>
                    .
                  </p>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <ButtonGA type="submit" className="btn btn-default">
                        Simular crédito
                      </ButtonGA>
                    </Grid>
                    <Grid className="p-relative" item xs={12} sm={6}>
                      <ButtonGA id="button-solicitar-credito" className="btn btn-outline" onClick={redirectToWebapp}>
                        Solicitar crédito
                      </ButtonGA>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>

            {this.state.openModalResultado && (
              <ResultadoSimulacao
                open={this.state.openModalResultado}
                onClose={this.closeResultado}
                onRecalcular={this.handleRecalcular}
                resultado={this.state.resultadoSimulacao}
                paramsSimulacao={this.state.paramsSimulacao}
                cobertura={this.state.cobertura}
                produto={theme.name}
              />
            )}
          </>
        )}
      </section>
    );
  }
}

export default FormSimulacao;
