import styled,{css} from 'styled-components';
import { BiPlusMedical } from "react-icons/bi";
import { AiOutlinePlayCircle } from "react-icons/ai";

export const Container = styled.div`
    width:65%;
    display:flex;
    flex-direction:column;

    @media(max-width:900px){
        width:90%;
        display:flex;
        margin-top:3rem;
    }
    
    @media(max-width:600px){        
        margin: 3rem auto 0;
    }    
`;

export const Video = styled.div`
    position:relative;
    width:80%;
    max-width: 100%;
    margin-left: auto;

    @media(max-width:1120px){        
        margin-left: 7rem;
        width: 100%;
    } 

    @media(max-width:900px){        
        margin: 0;
    } 

    video{
       width: 100%;
    }
    >a{
        position:absolute;
        z-index:5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-style: preserve-3d;
    }

`;

const icons = css`
    color:var(--color-yellow);
    width:4rem;
    height:4rem;
    @media(max-width:600px){
        width:35px;
        height:35px;
    }
    
`;
export const IconBiPlusMedicalUm = styled(BiPlusMedical)`
    ${icons}
    position:absolute;
        top: -2rem;
        left: -2rem;
    @media(max-width:600px){
        top: -1.2rem;
        left: -1.2rem;
    }
`;
export const IconBiPlusMedicalDois = styled(BiPlusMedical)`
    ${icons}
    position:absolute;
        top: -2rem;
        right: -2rem;
    @media(max-width:600px){
        top: -1.2rem;
        right: -1.2rem;
    }    
`;
export const IconBiPlusMedicalTres = styled(BiPlusMedical)`
    ${icons}
    position:absolute;
    bottom: -2rem;
    right: -2rem;
    @media(max-width:600px){
        bottom: -1.2rem;
        right: -1.2rem;
    }
`;
export const IconBiPlusMedicalQuatro = styled(BiPlusMedical)`
    ${icons}
    position:absolute;
    bottom: -2rem;
    left: -2rem;
    @media(max-width:600px){
        bottom: -1.2rem;
        left: -1.2rem;
    }
`;
export const IconAiOutlinePlayCircle = styled(AiOutlinePlayCircle)`
    color:var(--secondaryTextColor);
    width:6rem;
    height:6rem;
    
    cursor:pointer;
    @media(max-width:600px){
        width:4.5rem;
        height:4.5rem;
    }
`;