import React from 'react';
import { Container, SubText } from './styles';
import TextCenter from '../TextCenter';

const ColumnLeft: React.FC = () =>{    

    return(
        <Container>
            <TextCenter text="Mais alunos e menos burocracia">
                <SubText>Quer saber como?<br/>Assista ao vídeo.</SubText>
            </TextCenter>         
        </Container>
    )
}

export default ColumnLeft;