/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import querystring from 'query-string';

import FormSimulacao from '../FormSimulacao';
import * as simuladorService from '../../utils/simulador-service';
import TabScrollButton from '../UI/TabScrollButton';
import DefaultContext from '../../themes/DefaultContext';
import * as themes from '../../themes';

import '../../assets/css/Simulacao.css';

const FrameSimulador = () => {
  const [tabs, setTabs] = useState([
    {
      label: 'Graduação',
      niveis: ['GRADUAÇÃO'],
      hide: true,
    },
    {
      label: 'Ensino Técnico',
      niveis: ['TÉCNICO'],
      hide: true,
    },
  ]);
  const [paramsBolsa, setParamsBolsa] = useState<any>();
  const [tabIndex, setTabIndex] = useState(0);

  const queryParams = querystring.parse(window.location.search);

  async function initByInstituicaoCodigo(codigoList) {
    let activeRoutes = [...tabs];

    if (codigoList) {
      codigoList = codigoList.split('|');
      const response = await simuladorService.getInstituicoesByCodigo(
        codigoList,
      );
      setParamsBolsa(response.instituicoes ? response : null);
      activeRoutes = activeRoutes.filter((item) =>
        item.niveis.find((nivel) =>
          response.niveis.find((nvl) => nvl.includes(nivel)),
        ),
      );
    }
    activeRoutes.forEach((item) => (item.hide = false));
    setTabs(activeRoutes);
  }

  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    
    if(queryParams.ie){
      initByInstituicaoCodigo(queryParams.ie);
    }
  }, []);

	const redirectToWebapp = () => {
    const page = window.parent || window;    
		page.location.href = `${process.env.REACT_APP_URL_WEBAPP}${queryParams.ie ? `?bolsa=${queryParams.ie}` : ''}`;
	};

  const activeTab = tabs[tabIndex];

  return (
    <DefaultContext.Provider
      value={{
        redirectToWebapp,
        theme: themes.credies,
      }}
    >
      <Container maxWidth="lg" style={{ paddingBottom: 20 }}>
        <Grid item xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
          <Grid
            item
            xs={12}
            className="container-simulacao"
            style={{ boxShadow: 'none' }}
          >
            <Tabs
              className="tabs-simulacao"
              value={tabIndex}
              variant="scrollable"
              scrollButtons="on"
              ScrollButtonComponent={TabScrollButton}
            >
              {tabs.map((item, index) => {
                return (
                  !item.hide && (
                    <div
                      key={index}
                      className="tab-simulacao"
                      onClick={() => setTabIndex(index)}
                    >
                      <Tab
                        label={item.label}
                        className={index === tabIndex ? 'active' : ''}
                      />
                    </div>
                  )
                );
              })}
            </Tabs>

            <FormSimulacao
              niveis={activeTab ? activeTab.niveis : []}
              paramsBolsa={paramsBolsa}
            />
          </Grid>
        </Grid>
      </Container>
    </DefaultContext.Provider>
  );
};

export default FrameSimulador;
