import React from 'react';
import { Link } from 'react-scroll';

function LinkGA(props) {

    const onClick = () => {      
        
        if(window.location.pathname.indexOf('/politica-privacidade') >= 0){
            window.location.href = props.href;
        }

        if(props.onClick) {
            props.onClick();
        }
    };

    const childProps = {...props, onClick}

    return ( 
        <Link {...childProps}>{props.children}</Link>
    )
}

export default LinkGA;
