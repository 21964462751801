import React, { useState, useEffect, useRef, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Onda from '../components/Onda';
import ButtonGA from '../components/UI/ButtonGA';
import LinkGA from '../components/UI/LinkGA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowCircleDown} from '@fortawesome/free-solid-svg-icons';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DefaultContext from '../themes/DefaultContext';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import InputDuracaoCredito from "../components/UI/InputDuracaoCredito";
import { textoTipoPeriodo, tiposPeriodo } from '../utils/simulador-service';
import BlockBackButton from './../components/BlockBackButton';
import { useReactToPrint } from 'react-to-print';

import LogoHeader from '../assets/img/logo-header.png';
import '../assets/css/ResultadoSimulacao.css';

const TransitionModal = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid #eee',
        borderRadius: '0px !important',
        boxShadow: 'none',
        '&:not(:last-child)': {
        borderBottom: 0,
        },
        '&:before': {
        display: 'none',
        },
        '&$expanded': {
        margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);
  
const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: '#f9f9f9',
        borderBottom: '1px solid #eee',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
        minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
        margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const useStyles = makeStyles(theme => ({
    buttonProgress: {
        marginLeft: 12
    }
}));

const ResultadoSimulacao = (props) => {
    const context = useContext(DefaultContext);
    const classes = useStyles();

    const { paramsSimulacao, cobertura } = props;
    
    const [duracaoDoCredito, setDuracaoDoCredito] = useState(paramsSimulacao.duracaoDoCredito);
    const [parcelas, setParcelas] = useState([]);
    const [simulacaoProduto, setSimulacaoProduto] = useState({});
    const [expanded, setExpanded] = React.useState(false);
    const [modalParcelas, setModalParcelas] = React.useState(false);
    const [enviandoPorEmail, setEnviandoPorEmail] = React.useState(false);
    const [emailSucesso, setEmailSucesso] = React.useState(false);

    const printComponentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => printComponentRef.current,
    });

    useEffect(() => {
        if (props.open && props.resultado) {
            setSimulacaoProduto(props.resultado.simulacao[paramsSimulacao.produto]);
        }        
    }, [props.open, props.resultado]);

    useEffect(() => {
        if(emailSucesso) {
            setTimeout(function(){
                setEmailSucesso(false);
            }, 3000);
        }
    }, [emailSucesso]);

    useEffect(() => {
        if (simulacaoProduto.valorPorSemestre) {
            if (tipoPeriodo === tiposPeriodo.semestral) {
              montaParcelas();
            } else if (tipoPeriodo === tiposPeriodo.anual) {
              montaParcelasAnual();
            } else if (tipoPeriodo === tiposPeriodo.unico) {
              montaParcelaUnica();
            } else if (tipoPeriodo === tiposPeriodo.quadrimestral) {
              montaParcelasQuadrimestral();
            }
        }
    }, [simulacaoProduto])

    const tipoPeriodo = paramsSimulacao.tipoPeriodo;
    const valorMensalidade = paramsSimulacao.mensalidade_curso;
    const valorParcela = valorMensalidade - simulacaoProduto.valorParcela;
    const valorParcelaInt = Math.floor(valorParcela);
    const valorParcelaCentavos = ("0" + ((valorParcelaInt - valorParcela).toFixed(2) * 100)).slice(-2);
    const valorMensalidadeFormatada = valorMensalidade != undefined ? valorMensalidade.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : null;
    
    const getMeses = () => {
        if (tipoPeriodo === tiposPeriodo.semestral) {
          return 6;
        }
        if (tipoPeriodo === tiposPeriodo.quadrimestral) {
          return 4;
        }
        return 12;
    };

    const nMeses =  getMeses()
    const valorIntegral = paramsSimulacao.mensalidade_curso * paramsSimulacao.duracaoDoCredito * nMeses;
    const valorIntegralFormatado = valorIntegral != undefined ? valorIntegral.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : null;
    const valorSimulacao = simulacaoProduto.totalApagar != undefined ? simulacaoProduto.totalApagar.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : null;
    const numParcelas =
        tipoPeriodo === tiposPeriodo.semestral ||
        tipoPeriodo === tiposPeriodo.quadrimestral
          ? paramsSimulacao.duracaoDoCredito * nMeses
          : (paramsSimulacao.duracaoDoCredito * nMeses) / 2;
    const taxaAdministracao = simulacaoProduto.juros ? simulacaoProduto.juros.split('Taxa adm. de')[1] : null;

    const handleModalParcelasClose = () => {
        setModalParcelas(false);
    };

    const handleModalParcelasOpen = () => {
        setModalParcelas(true);
    };

    const handleDuracaoCreditoPlus = () => {
        if((tipoPeriodo === tiposPeriodo.semestral || tipoPeriodo === tiposPeriodo.quadrimestral) && duracaoDoCredito < 20){
            setDuracaoDoCredito(duracaoDoCredito+1);
        }else if(tipoPeriodo === tiposPeriodo.anual && duracaoDoCredito < 20){
            setDuracaoDoCredito(duracaoDoCredito+2);
        }
    };

    const handleDuracaoCreditoMinus = () => {
        if((tipoPeriodo === tiposPeriodo.semestral || tipoPeriodo === tiposPeriodo.quadrimestral) && duracaoDoCredito > 1){
            setDuracaoDoCredito(duracaoDoCredito-1);
        }else if(tipoPeriodo === tiposPeriodo.anual && duracaoDoCredito > 2){
            setDuracaoDoCredito(duracaoDoCredito-2);
        }
    };

    const handleSemestreChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        setModalParcelas(false);
    };

    const handleRecalcular = () => {
        setExpanded(false);
        props.paramsSimulacao.duracaoDoCredito = duracaoDoCredito;
        props.onRecalcular();
    };

    const handleEnviarEmail = () => {
        setEnviandoPorEmail(true);

        var data = {
            "produto": 'maisacesso',
            "email": paramsSimulacao.email,
            "parcelas": parcelas,
            "numParcelas": numParcelas,
            "percentualSolicitado": paramsSimulacao.percentualSolicitado,
            "valorParcelaInt": valorParcelaInt,
            "valorParcelaCentavos": valorParcelaCentavos,
            "valorMensalidade": valorMensalidadeFormatada,
            "valorIntegral": valorIntegralFormatado,
            "valorSimulacao": valorSimulacao
        };

        axios.post(process.env.REACT_APP_URL_HOST_API+'/v1/portal/emails/simulacao', data).then(() => {
            setEnviandoPorEmail(false);
            setEmailSucesso(true);
        });
    }

    const handleChangePercentual = event => {
        if(cobertura.tipo === 'O') {
            props.paramsSimulacao.percentualSolicitado = event.target.value;
        } else {
            if(event.target.value < cobertura.valorMinimo)
                props.paramsSimulacao.percentualSolicitado = cobertura.valorMinimo;

            if(event.target.value > cobertura.valorMaximo)
                props.paramsSimulacao.percentualSolicitado = cobertura.valorMaximo;
        }

        handleRecalcular();
    };

    const handlePercentualSolicitadoMinus = () => {
        if(paramsSimulacao.percentualSolicitado > cobertura.valorMinimo) {
            props.paramsSimulacao.percentualSolicitado--;
            handleRecalcular();
        }
    };

    const handlePercentualSolicitadoPlus = () => {
        if(paramsSimulacao.percentualSolicitado < cobertura.valorMaximo) {
            props.paramsSimulacao.percentualSolicitado++;
            handleRecalcular();
        }
    };

    const montaParcelas = () => {
        var dataAtual = new Date();
        var mesAtual = (dataAtual.getMonth() > 5) ? 0 : 6;
        var anoAtual = (dataAtual.getMonth() > 5) ? (dataAtual.getFullYear() +1) : dataAtual.getFullYear();
        var arrMeses = {};
        var arrAnos = {};
        var i = 0;

        for (i = 1; i <= (paramsSimulacao.duracaoDoCredito * nMeses); i++) {
            if (mesAtual > 11) {
                mesAtual = 1;
                anoAtual++;
                arrMeses['month_' + i] = mesAtual;
                arrAnos['year_' + i] = anoAtual;
            }
            else {
                mesAtual++;
                arrMeses['month_' + i] = mesAtual;
                arrAnos['year_' + i] = anoAtual;
            }
        }

        var semestres = simulacaoProduto.valorPorSemestre;

        for (i = 0; i < semestres.length; i++) {
            semestres[i]['parcelasDurante'] = [];
            semestres[i]['panelId'] = 'semestre-'+semestres[i]['semestre'];

            for (var x = 0; x < semestres[i].parcelas.length; x++) {
                var mesParcela = arrMeses['month_' + (((semestres[i].semestre - 1) * 6) + semestres[i].parcelas[x].parcela)];
                var anoParcela = arrAnos['year_' + (((semestres[i].semestre - 1) * 6) + semestres[i].parcelas[x].parcela)];

                if (mesParcela < 10)
                    mesParcela = '0' + mesParcela;

                semestres[i]['parcelasDurante'].push({
                    'parcela': semestres[i].parcelas[x].parcela,
                    'valor': valorParcela.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
                    'mes': mesParcela + "/" + anoParcela
                });

                var totalPrevisto = semestres[i]['parcelas'][x]['totalPrevisto'];
                semestres[i]['parcelas'][x]['totalPrevisto'] = totalPrevisto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

                var dataVencimento = semestres[i]['parcelas'][x]['dataVencimento'];
                semestres[i]['parcelas'][x]['dataVencimento'] = dataVencimento.replace(/\-/g, '/');

                semestres[i]['parcelas'][x]['parcela'] = ((semestres[i]['semestre'] - 1) * 6) + semestres[i]['parcelas'][x]['parcela'];
            }
        }
        setParcelas(semestres);
    };
    
    const montaParcelasAnual = () => {

        var dataAtual = new Date();
        var mesAtual = (dataAtual.getMonth() > 5) ? 0 : 6;
        var anoAtual = (dataAtual.getMonth() > 5) ? (dataAtual.getFullYear() +1) : dataAtual.getFullYear();
        var arrMeses = {};
        var arrAnos = {};
        var i = 0;

        for (i = 1; i <= ((paramsSimulacao.duracaoDoCredito/2) * nMeses); i++) {
            if (mesAtual > 11) {
                mesAtual = 1;
                anoAtual++;
                arrMeses['month_' + i] = mesAtual;
                arrAnos['year_' + i] = anoAtual;
            }
            else {
                mesAtual++;
                arrMeses['month_' + i] = mesAtual;
                arrAnos['year_' + i] = anoAtual;
            }
        }

        var anos = simulacaoProduto.valorPorSemestre;
        for (i = 0; i < anos.length ; i++){
            anos[i]['parcelasDurante'] =[];
            anos[i]['panelId'] = 'ano-'+(i+1);
            anos[i].parcelas = anos[i].parcelas.concat(anos[i+1].parcelas);
            anos.splice(i+1, 1);
            anos[i].semestre = i+1;
            for (var x = 0; x < anos[i].parcelas.length; x++){
                anos[i].parcelas[x].parcela = x+1;
                var mesParcela = arrMeses['month_' + ((i * 12) + anos[i].parcelas[x].parcela)];
                var anoParcela = arrAnos['year_' + ((i * 12) + anos[i].parcelas[x].parcela)];

                if (mesParcela < 10)
                    mesParcela = '0' + mesParcela;

                anos[i]['parcelasDurante'].push({
                    'parcela': anos[i].parcelas[x].parcela,
                    'valor': valorParcela.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
                    'mes': mesParcela + "/" + anoParcela
                });

                var totalPrevisto = anos[i]['parcelas'][x]['totalPrevisto'];
                anos[i]['parcelas'][x]['totalPrevisto'] = totalPrevisto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

                var dataVencimento = anos[i]['parcelas'][x]['dataVencimento'];
                anos[i]['parcelas'][x]['dataVencimento'] = dataVencimento.replace(/\-/g, '/');

                anos[i]['parcelas'][x]['parcela'] = ((anos[i]['semestre'] - 1) * 12) + anos[i]['parcelas'][x]['parcela'];
            }
        }
        setParcelas(anos);
    };


  const montaParcelaUnica = () => {
    var dataAtual = new Date();
    var mesAtual = dataAtual.getMonth() > 5 ? 0 : 6;
    var anoAtual =
      dataAtual.getMonth() > 5
        ? dataAtual.getFullYear() + 1
        : dataAtual.getFullYear();
    var arrMeses = {};
    var arrAnos = {};
    var i = 0;
    const totalMeses = paramsSimulacao.duracaoDoCredito * nMeses;
    for (i = 1; i <= totalMeses; i++) {
      if (mesAtual > 11) {
        mesAtual = 1;
        anoAtual++;
        arrMeses['month_' + i] = mesAtual;
        arrAnos['year_' + i] = anoAtual;
      } else {
        mesAtual++;
        arrMeses['month_' + i] = mesAtual;
        arrAnos['year_' + i] = anoAtual;
      }
    }
    var unico = {};
    unico.parcelas = simulacaoProduto.valorPorSemestre
      ?.map((item) => item.parcelas)
      .flat();
    unico['parcelasDurante'] = [];
    unico['panelId'] = 'unico';
    unico.semestre = 1;
    for (var x = 0; x < unico.parcelas.length; x++) {
      const parcelaAtual = x + 1;
      unico.parcelas[x].parcela = parcelaAtual;
      var mesParcela = arrMeses['month_' + parcelaAtual];
      var anoParcela = arrAnos['year_' + parcelaAtual];
      if (mesParcela < 10) mesParcela = '0' + mesParcela;
      unico['parcelasDurante'].push({
        parcela: parcelaAtual,
        valor: valorParcela.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        mes: mesParcela + '/' + anoParcela,
      });
      var totalPrevisto = unico['parcelas'][x]['totalPrevisto'];
      unico['parcelas'][x]['totalPrevisto'] = totalPrevisto.toLocaleString(
        'pt-br',
        {
          style: 'currency',
          currency: 'BRL',
        },
      );
      var dataVencimento = unico['parcelas'][x]['dataVencimento'];
      unico['parcelas'][x]['dataVencimento'] = dataVencimento.replace(
        /\-/g,
        '/',
      );
      unico['parcelas'][x]['parcela'] =
        (unico['semestre'] - 1) * 12 + unico['parcelas'][x]['parcela'];
    }
    setParcelas([unico]);
  };

  const montaParcelasQuadrimestral = () => {
    var dataAtual = new Date();
    var mesAtual = dataAtual.getMonth() > 5 ? 0 : 6;
    var anoAtual =
      dataAtual.getMonth() > 5
        ? dataAtual.getFullYear() + 1
        : dataAtual.getFullYear();
    var arrMeses = {};
    var arrAnos = {};
    var i = 0;

    for (i = 1; i <= paramsSimulacao.duracaoDoCredito * nMeses; i++) {
      if (mesAtual > 11) {
        mesAtual = 1;
        anoAtual++;
        arrMeses['month_' + i] = mesAtual;
        arrAnos['year_' + i] = anoAtual;
      } else {
        mesAtual++;
        arrMeses['month_' + i] = mesAtual;
        arrAnos['year_' + i] = anoAtual;
      }
    }

    var quadrimestres = simulacaoProduto.valorPorSemestre;

    for (i = 0; i < quadrimestres?.length; i++) {
      quadrimestres[i]['parcelasDurante'] = [];
      quadrimestres[i]['panelId'] = 'semestre-' + quadrimestres[i]['semestre'];

      for (var x = 0; x < quadrimestres[i].parcelas.length; x++) {
        var mesParcela =
          arrMeses[
            'month_' +
              ((quadrimestres[i].semestre - 1) * 4 +
                quadrimestres[i].parcelas[x].parcela)
          ];
        var anoParcela =
          arrAnos[
            'year_' +
              ((quadrimestres[i].semestre - 1) * 4 +
                quadrimestres[i].parcelas[x].parcela)
          ];

        if (mesParcela < 10) mesParcela = '0' + mesParcela;

        quadrimestres[i]['parcelasDurante'].push({
          parcela: quadrimestres[i].parcelas[x].parcela,
          valor: valorParcela.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
          mes: mesParcela + '/' + anoParcela,
        });

        var totalPrevisto = quadrimestres[i]['parcelas'][x]['totalPrevisto'];
        quadrimestres[i]['parcelas'][x]['totalPrevisto'] =
          totalPrevisto.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          });

        var dataVencimento = quadrimestres[i]['parcelas'][x]['dataVencimento'];
        quadrimestres[i]['parcelas'][x]['dataVencimento'] =
          dataVencimento.replace(/\-/g, '/');

        quadrimestres[i]['parcelas'][x]['parcela'] =
          (quadrimestres[i]['semestre'] - 1) * 4 +
          quadrimestres[i]['parcelas'][x]['parcela'];
      }
    }
    setParcelas(quadrimestres);
  };

  const getExpansionText = (value) => {
    if (tipoPeriodo === tiposPeriodo.unico) return 'Contratação';
    if (tipoPeriodo === tiposPeriodo.semestral) return `${value}º Semestre`;
    if (tipoPeriodo === tiposPeriodo.anual) return `${value}º Ano`;
    if (tipoPeriodo === tiposPeriodo.quadrimestral)
      return `${value}º Quadrimestre`;
  };

    const goToWebapp = () => {
        props.onClose();
        context.redirectToWebapp();
        localStorage.setItem('parametrosSimulacao', JSON.stringify(paramsSimulacao));
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullScreen={!isWidthUp('sm', props.width)}
            maxWidth="md"
            TransitionComponent={TransitionModal}
            className="modal-resultado-simulacao"
            PaperProps={{id:"modal_simulacao"}}>

            <Container className="modal-resultado-simulacao-root" ref={printComponentRef}>
                {props.open && <BlockBackButton onBack={props.onClose} /> }
                
                <header>
                    <IconButton edge="end"
                        onClick={props.onClose}
                        aria-label="Fechar"
                        className="close-button">
                        <CloseIcon />
                    </IconButton>
                </header>

                <Grid container>
                    <Grid item xs={12} className="simulacao-resumo">
                        <Grid item xs={12} className="logo-ies">
                            <img src={LogoHeader} alt="Mais Acesso" className="img-fluid img-logo-credies d-block mx-auto mb-4" />
                        </Grid>
                        <div className="resumo-simulacao-text">
                            Com crédito de 
                            {cobertura && cobertura.tipo === 'F' && <span className="bold"> {paramsSimulacao.percentualSolicitado ? paramsSimulacao.percentualSolicitado : cobertura.valores.valor}% </span> }

                            {cobertura && cobertura.tipo === 'O' &&
                                <Select className="percentual-select" value={paramsSimulacao.percentualSolicitado} onChange={handleChangePercentual}>
                                    {cobertura.valores.map((porcentagem, index) => {
                                        return <MenuItem key={index} value={porcentagem.valor}>{porcentagem.valor}%</MenuItem>
                                    })}
                                </Select>
                            }

                            {cobertura && cobertura.tipo === 'V' &&
                                <div className="percentual-variavel">
                                    <a onClick={handlePercentualSolicitadoMinus}><FontAwesomeIcon icon={faMinusCircle} /></a>
                                    <input type="number" value={paramsSimulacao.percentualSolicitado} onChange={handleChangePercentual}></input>
                                    <span>%</span>
                                    <a onClick={handlePercentualSolicitadoPlus}><FontAwesomeIcon icon={faPlusCircle} /></a>
                                </div>
                            }


                            para seu curso,<br></br>
                            você pagará à instituição o valor de
                            <div className="simulacao-valor">
                                <span className="prefixo">R$</span>
                                <span className="valor">{valorParcelaInt}</span>
                                <span className="centavos">,{valorParcelaCentavos}</span>
                            </div>
                            de uma mensalidade de <span className="bold">{valorMensalidadeFormatada}</span>
                        </div>
                        <Grid item xs={12}>
                            <Grid container justify="center" spacing={0}>
                                <Grid item xs={12} md={4}>
                                    <ButtonGA className="btn btn-default btn-solicitar-credito" onClick={goToWebapp}>Gostei, quero solicitar!</ButtonGA>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <ButtonGA className="btn btn-outline btn-simular-novamente" onClick={props.onClose}>Quero simular novamente</ButtonGA>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <LinkGA 
                            className="lk-simulacao-completa" 
                            to="simulacao_completa"
                            containerId="modal_simulacao"
                            smooth={true} duration={500}
                            >Veja a simulação completa <FontAwesomeIcon icon={faArrowCircleDown} /></LinkGA>
                        
                    </Grid>
                </Grid>
                <Onda image="onda-1" />
                <Onda image="onda-3" />
                <Grid container justify="center" className="simulacao-completa" id="simulacao_completa">
                    <Grid item xs={12} md={8} >
                        <h5 className="titulo">Veja o que você precisa para contratar:</h5>

                        <span className="subtitulo">Documentos</span>
                        <ul>
                            <li>Documentos de identificação</li>
                            <li>Comprovante de residência atualizado</li>
                        </ul>

                    </Grid>
                    <Grid id="simulacao_completa_parcelas" name="simulacao_completa_parcelas" item xs={12} md={10} className="simulacao-completa-parcelas">
                        {tipoPeriodo !== tiposPeriodo.unico && 
                        (<div className="calculadora">
                            Quero contratar por
                            <div className="semestres">
                                <a onClick={handleDuracaoCreditoMinus}><FontAwesomeIcon icon={faMinusCircle} /></a>
                                <InputDuracaoCredito className="duracaoDoCredito" duracaoDoCredito={duracaoDoCredito} tipoPeriodo={tipoPeriodo}></InputDuracaoCredito>
                                <a onClick={handleDuracaoCreditoPlus}><FontAwesomeIcon icon={faPlusCircle} /></a>
                            </div>
                            <span className="periodo-txt">
                                {tipoPeriodo===tiposPeriodo.anual && 'anos'}                        
                                {tipoPeriodo===tiposPeriodo.semestral && 'semestres'}
                                {tipoPeriodo === tiposPeriodo.quadrimestral && 'quadrimestres'}
                            </span>

                            <Button className="btn btn-outline" onClick={handleRecalcular}>Recalcular</Button>
                        </div>)}
                        <div className="parcelas">
                            {`Veja como fica a parcela${
                            tipoPeriodo !== tiposPeriodo.unico
                              ? ` por ${textoTipoPeriodo?.[tipoPeriodo]}`
                              : ''
                          }:`}                            
                          {parcelas.map((semestre, index) => {
                                return <ExpansionPanel key={index} expanded={expanded === semestre.panelId} onChange={handleSemestreChange(semestre.panelId)}>
                                    <ExpansionPanelSummary>
                                        <Typography className="semestre-header">{getExpansionText(semestre.semestre)}<FontAwesomeIcon icon={faArrowCircleDown} /></Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container>
                                            <Grid item xs={12} md={6} className="parcelas-durante">
                                                <div className="parcelas-header">
                                                    O QUE VOCÊ PAGA DURANTE
                                                </div>
                                                {semestre.parcelasDurante.map((parcela, index) => {
                                                    return <Grid container className="parcelas-item" key={index}>
                                                        <Grid item xs><strong>PARCELA</strong><br/>Parcela {parcela.parcela}/{semestre.parcelasDurante.length}</Grid>
                                                        <Grid item xs><strong>DATA</strong><br/>{parcela.mes}</Grid>
                                                        <Grid item xs><strong>VALOR</strong><br/>{parcela.valor}</Grid>
                                                    </Grid>
                                                })}
                                            </Grid>
                                            <Grid item xs={12} md={6} className="parcelas-depois">
                                                <div className="parcelas-header">
                                                    O QUE VOCÊ PAGA DEPOIS <FontAwesomeIcon icon={faInfoCircle} onClick={handleModalParcelasOpen} />
                                                </div>
                                                {modalParcelas && 
                                                <div className="modal-parcelas">
                                                    <IconButton edge="end"
                                                        onClick={handleModalParcelasClose}
                                                        aria-label="Fechar"
                                                        className="close-button">
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <h1>COMO AS PARCELAS SÃO CALCULADAS?</h1>
                                                    <p>
                                                        Por ser uma fundação sem fins lucrativos, em vez de juros remuneratórios capitalizados, o Mais Acesso (solução da Fundacred) opera mediante a cobrança de taxa administrativa* não capitalizada, uma opção 
                                                        bastante acessível.
                                                        <br/><br/>
                                                        Para o curso escolhido, fica assim:
                                                        <br/><br/>
                                                        *  {taxaAdministracao}, computado entre a data da contratação do crédito e a efetiva restituição.
                                                        <br/><br/>
                                                        O valor contratado será atualizado pelos percentuais aplicados pela instituição de ensino
                                                        para o reajuste das mensalidades ou pela variação mensal INPC, IPCA ou outro índice governamental, 
                                                        conforme previsto no regulamento do programa.
                                                        <br/><br/>
                                                        Consulte a taxa, índice de atualização e demais regras no regulamento do programa de crédito 
                                                        educacional.
                                                    </p>
                                                </div>
                                                }
                                                {semestre.parcelas.map((parcela, i) => {
                                                    return <Grid container className="parcelas-item" key={i}>
                                                        <Grid item xs><strong>PARCELA</strong><br/>Parcela {parcela.parcela}/{numParcelas}</Grid>
                                                        <Grid item xs><strong>DATA</strong><br/>{parcela.dataVencimento.substring(3, 10)}</Grid>
                                                        <Grid item xs><strong>VALOR</strong><br/>{parcela.totalPrevisto}</Grid>
                                                    </Grid>
                                                })}
                                            </Grid>
                                        </Grid> 
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            })}
                        </div>

                        <div className="valores">
                            <div className="titulo">Valor total do investimento</div>
                            <div className="texto">
                                Pagando o <span className="bold">valor integral</span> da mensalidade, no <span className="bold">prazo normal</span>, o total é de <span className="bold">{valorIntegralFormatado}</span>
                            </div>
                            <div className="texto">
                                Pagando <span className="bold">parte da mensalidade</span> com o Mais Acesso em <span className="bold">prazo maior</span>, o total é de <span className="valor-final">{valorSimulacao}</span>
                            </div>
                        </div>
                        <Grid container spacing={2} className="btn-fullWidth" justify="center">
                            {paramsSimulacao.email && emailSucesso && <Grid item xs={12}>
                                <Alert severity="success">Simulação enviada por email!</Alert>
                            </Grid>}
                            <Grid item xs={12} md={4}>
                                <Button className="btn btn-outline" onClick={handlePrint}>Imprimir simulação</Button>
                            </Grid>

                            {/* DESABILITADO ENVIO DE EMAIL TEMPORARIAMENTE */}
                            {/* {paramsSimulacao.email && <Grid item xs={12} md={4}>
                                <Button className="btn btn-outline" onClick={handleEnviarEmail} disabled={enviandoPorEmail}>
                                    Enviar por email
                                    {enviandoPorEmail && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </Grid>} */}
                            <Grid item xs={12} md={4}>
                                <ButtonGA className="btn btn-default" onClick={goToWebapp}>Gostei, quero solicitar!</ButtonGA>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}

export default withWidth()(ResultadoSimulacao);
