import React, { Fragment, useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Switch, Route, withRouter, NavLink } from "react-router-dom";

import FormSimulacao from './FormSimulacao';
import * as simuladorService from '../utils/simulador-service';

const regexLastSlash = /\/$/;

const TabsSimulacao = (props) => {
    const [routes, setRoutes] = useState([
        { path: '/graduacao', label: 'Graduação', niveis: ['GRADUAÇÃO'], hide: true },
        { path: '/ensino-tecnico', label: 'Ensino Técnico', niveis: ['TÉCNICO'], hide: true },
    ]);
    const [paramsBolsa, setParamsBolsa] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        async function initByInstituicaoCodigo(codigoList) {
            let activeRoutes = [...routes];
            const pathname = props.location.pathname.replace(regexLastSlash, '');            
            let initialRoute = (activeRoutes.find(item => pathname === item.path)) || routes[0];

            if (codigoList) {
                codigoList = codigoList.split('|');
                const response = await simuladorService.getInstituicoesByCodigo(codigoList);
                setParamsBolsa(response.instituicoes ? response : null);

                activeRoutes = activeRoutes.filter(item => item.niveis.find(nivel => response.niveis.indexOf(nivel) >= 0));
                if (activeRoutes[0]) {
                    initialRoute = activeRoutes[0];
                }
            }
            
            activeRoutes.forEach(item => item.hide = false);
            setRoutes(activeRoutes);            

            props.history.push(`${initialRoute.path}/${props.location.search}`);
        }

        initByInstituicaoCodigo(getUrlParameter('ie'));
    }, []);
    
    const renderPageRoute = (props) => {
        const pathname = props.location.pathname.replace(regexLastSlash, '');

        let index = routes.findIndex(item => pathname === item.path);
        index = index >= 0 ? index : 0;
        setTabIndex(index);

        const route = routes[index];
        return <FormSimulacao niveis={route ? route.niveis : []} paramsBolsa={paramsBolsa} />;
    }

    const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    return (
        <Fragment>
            <Tabs className="tabs-simulacao" value={tabIndex}>
                {
                    routes.map((item, index) => {
                        return !item.hide &&
                            (
                                <div key={index} className="tab-simulacao">
                                    <Tab label={item.label}
                                        component={NavLink}
                                        value={item.path}
                                        to={{ ...props.location, pathname: item.path }} />
                                </div>
                            );
                    })
                }
            </Tabs>
            <Switch>
                <Route path="/graduacao" render={renderPageRoute} />
                <Route path="/ensino-tecnico" render={renderPageRoute} />
            </Switch>
        </Fragment>
    );
};

export default withRouter(TabsSimulacao);