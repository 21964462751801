import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import BlocoCurso from './BlocoCurso';
import BlocoFormatura from './BlocoFormatura';

import DefaultContext from '../themes/DefaultContext';

function BlocoComoFuncionaDesk(props) {

	return (
		<DefaultContext.Consumer>
			{({theme})=> (
			<Fragment>
				<Grid container>
					<Grid item sm={5} md={5}>
						<BlocoFormatura />
					</Grid>
					<Grid item md={2} sm={2} className="p-relative">
						<img src={theme.comoFunciona.imgModelo} className={theme.name==="crednex" ? "modelo-como-funciona crednex" : "modelo-como-funciona"} alt={theme.comoFunciona.imgAltModelo} />
					</Grid>
					<Grid item sm={5} md={5}>
						<BlocoCurso />
					</Grid>
				</Grid>
			</Fragment>
			)}
		</DefaultContext.Consumer>
	);
}

export default BlocoComoFuncionaDesk;