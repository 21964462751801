import { useEffect } from 'react';

const ZendeskWidget = (props) => {
  useEffect(() => {
    if (!window.zE) {
      insertScript();
    }
  });

  const onScriptLoad = () => {
    window.zE('messenger:on', 'close', () => window.zE('messenger', 'close'));
    window.zE('messenger', 'close');
  };

  const insertScript = () => {
    const script = document.createElement('script');
    script.async = true;
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${props.zendeskKey}`;
    script.addEventListener('load', onScriptLoad);
    document.body.appendChild(script);
  };

  return null;
};

export default ZendeskWidget;
