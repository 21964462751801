import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import "../assets/css/Footer.css";
import LogoHeader from "../assets/img/logo-header.png";

function Footer() {
  return (
    <footer>
      <Container maxWidth="lg">
        <Grid container justify="center">
          <Grid item xs={8} sm={8} md={2}>
            <img className="fluid" src={LogoHeader} alt="Mais Acesso" />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <nav className="text-center">
              <Link href="https://www.fundacred.org.br/site/quem-somos/">
                Quem Somos
              </Link>
              <Link href="https://fundacred.org.br/estudante-web/aviso-de-privacidade">
                Aviso de Privacidade
              </Link>
              <Link href="https://portal.fundacred.org.br/estudante-web/politica-cookies/">
                Política de Cookies
              </Link>
              <Link href="https://atendimentofundacred.zendesk.com/hc/pt-br">
                Contato
              </Link>
            </nav>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <p className="txt-address text-center">
              Av. Júlio de Castilhos, 44, Térreo - Porto Alegre - RS - CEP
              90030-130
              <br />
              CNPJ 88.926.381/0001-85
            </p>
          </Grid>
        </Grid>

        {/* Removido Temporariamente
				<Grid container justify="center">
					<Grid item xs={12} md={4}>
						<p className="small-txt mt-4 mb-4">Para esclarecer dúvidas e saber mais sobre as nossas soluções, entre em contato pela Central de Relacionamento.</p>
					</Grid>
				</Grid>

				<Grid container justify="center" spacing={3}>
					<Grid item xs={12} sm={12} md={3} lg={2}>
						<p className="txt-label-tel text-center">Capitais e regiões metropolitanas</p>
						<p className="txt-tel text-center">4007.2302</p>
					</Grid>
					<Grid item xs={12} sm={12} md={3} lg={2}>
						<p className="txt-label-tel text-center">Demais cidades</p>
						<p className="txt-tel text-center">0800.002.2302</p>
					</Grid>
					<Grid item xs={12} sm={12} md={3} lg={2}>
						<p className="txt-label-tel text-center">WhatsApp</p>
						<p className="txt-tel text-center">(51) 989.590.959</p>
					</Grid>

				</Grid>
				*/}

        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={10}>
            <p className="small-txt mt-5 textao">
              *Pague parte da mensalidade enquanto estuda e o restante somente
              após a data prevista para conclusão do curso ou término do vínculo
              com a instituição. Consulte regulamento.
              <br />
              <br />O estudante beneficiado pelo crédito educacional tem, a
              partir da data prevista para a conclusão do curso ou do término do
              vínculo com a instituição, sem período de carência, um tempo de
              restituição variável, que pode ser quitação à vista ou em parcelas
              de acordo com os critérios estabelecidos junto à instituição de
              ensino credora, que levam em conta o número de parcelas e o
              percentual de cobertura. Não há juros remuneratórios sobre o
              crédito contratado, apenas correção monetária e uma taxa de
              administração máxima de 0,80% ao mês. Em regra, numa contração
              hipotética - utilizando taxa de 0,80% ao mês, visando todas as
              disciplinas regulares de cada semestre letivo, e não
              contabilizando as possíveis variações nas mensalidades no decorrer
              da graduação, para um curso de 8 semestres com mensalidade de
              R$400,00, o estudante beneficiário de um crédito educacional de
              50% de cobertura pagaria mensalmente à instituição de ensino
              R$200,00, em 48 parcelas, totalizando R$9.600,00 (mais os
              reajustes anuais), e ao restituir o crédito, outras 48 parcelas
              que montariam R$13.478,40 (mais correção monetária).
            </p>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
