import iconCalendar from '../assets/img/icon-calendar.svg';
import iconDespesas from '../assets/img/icon-despesas.svg';
import iconPizza from '../assets/img/icon-group-2.svg';
import iconPercent from '../assets/img/icon-percent.svg';
import iconCalendar2 from '../assets/img/icon-calendar-2.svg';
import iconDiploma from '../assets/img/icon-diploma.svg';
import modelo from '../assets/img/modelo.png';

export default {
    name: 'credies',
    displayName: '+MAISACESSO',
    periodo: "Contratos semestrais*",
    helpPeriodo: "* Consulte a opção com a instituição",
    comoFunciona: {
        imgModelo: modelo,
        imgAltModelo: '',
        durante: {
            text: 'Contrate por quanto tempo precisar, de acordo com seu orçamento e projetos. Realize agora e no futuro com Mais Acesso.',
            items: [
                {
                    icon: iconCalendar,
                    text: 'Oportunidade de fazer o curso desejado'
                },
                {
                    icon: iconDespesas,
                    text: 'Tranquilidade financeira'
                },
                {
                    icon: iconPizza,
                    text: 'Foco no aprendizado'
                }
            ]
        },
        depois: {
            title: 'Depois da conclusão',
            text: 'Inicia-se o pagamento do restante do valor das mensalidades, sem juros remuneratórios e a menor taxa de crédito educacional do País.',
            items: [
                {
                    icon: iconPercent,
                    text: 'Crescimento profissional'
                },
                {
                    icon: iconCalendar2,
                    text: 'Pague o restante do valor das mensalidades**'
                },
                {
                    icon: iconDiploma,
                    text: 'Novas oportunidades de crescimento'
                }
            ]
        }
    },
}