import React, {Component} from 'react';
import Container from '@material-ui/core/Container/index';
import Grid from '@material-ui/core/Grid/index';
import Card from '@material-ui/core/Card/index';
import CardContent from '@material-ui/core/CardContent/index';
import '../assets/css/Politica-Privacidade.css';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


class PoliticaPrivacidade extends Component{

  constructor(props){
    super(props);
  }

  render(){

    return (
      <section id="politica-privacidade" className="politica-privacidade p-relative">
        <Container maxWidth="lg">
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={8}>
              <h2>Política de privacidade, coleta e tratamento de dados</h2>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={8}>
              <p className="text-center txt-politica">Escopo dessa política</p>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={8}>
              <p className="text-center txt-politica-privacidade">A Fundacred valoriza a sua privacidade, reconhecendo a necessidade de tratar os seus dados pessoais de forma justa e leal, pois acredita que a Privacidade é a extensão de sua personalidade e que, ao exercer o seu direito, você permite-se exercitar seu desenvolvimento como cidadão(ã) livre.</p>
              <p className="text-center txt-politica-privacidade">A Política de Privacidade da Fundacred define os termos relevantes para o tratamento de dados pessoais, obtidos como resultado do uso que você faz das soluções de acesso à educação orquestradas por nós, em parceria com Instituições de Ensino, Prestadores de Serviços Educacionais e demais interessados na sua transformação e desenvolvimento como cidadão, profissional e estudante.</p>
              <p className="text-center txt-politica-privacidade">Estamos comprometidos em proteger a confidencialidade, disponibilidade e a integridade das informações que você nos fornece, de acordo com a legislação vigente no Brasil relacionada à proteção de dados e à privacidade.</p>
              <p className="text-center txt-politica-privacidade">Esta Política de Privacidade foi elaborada para ajudar você a entender como coletamos e tratamos suas informações.</p>
              <p className="text-center txt-politica-privacidade">Se você tiver dúvidas ou perguntas sobre está política, entre em contato conosco.</p>
              <p className="text-center txt-politica-privacidade">A Fundacred poderá alterar o teor desta Política de Privacidade a qualquer momento, conforme sua necessidade, bem como para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo ao usuário verificá-la sempre que efetuar o acesso ao website da Fundacred ou a seus produtos e serviços oferecidos.</p>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={8}>
              <Card className="tabela-privacidade">
                <CardContent>
                  <Grid cotainer className="coluna-lg">
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Por que precisamos e como tratamos dados pessoais?</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Apenas coletamos, tratamos e armazenamos seus dados pessoais quando possuímos motivos e finalidades legais ou legítimas para fazê-lo. Tais como:
                          <ul>
                            <li><span>atender a solicitações de crédito educacional;&nbsp;</span></li>
                            <li><span>para tomar medidas antes de celebrar um contrato e/ou durante a sua execução;</span></li>
                            <li><span>quando for necessário cumprir uma obrigação a que a Fundacred esteja sujeita, para garantir conformidade com as leis e regulamentos vigentes, ou quando necessário para resguardar seus direitos;</span></li>
                            <li><span>onde for necessário, para os interesses legítimos da Fundacred, exceto onde esses interesses sejam substituídos por direitos e liberdades fundamentais do titular.</span></li>
                          </ul>
                          O uso de seus dados é essencial para que possamos verificar sua identidade, para permitir que você celebre um contrato de crédito educacional e para que o crédito educacional seja concedido. Também podemos usá-los para manter nossos registros atualizados, para notificá-lo sobre alterações em nossos serviços e para nos ajudar no desenvolvimento de novas soluções em crédito educacional e serviços relacionados à transformação positiva das pessoas por meio do acesso à educação.

                          Se você não fornecer as informações necessárias, não conseguiremos providenciar seu contrato de crédito educacional e não poderemos fornecer determinados serviços adicionais a você, os quais potencializam sua transformação enquanto cidadão e/ou estudante.

                          Suas informações também podem ser usadas para verificar sua identidade, para administrar pagamentos e também poderão ser usadas para análise e proteção ao crédito.

                          Podemos coletar informações sobre outras pessoas, por exemplo, seu garantidor/fiador. Se você nos der informações sobre outra pessoa, é sua responsabilidade garantir e confirmar que:

                          <ul>
                            <li><span>Você disse ao indivíduo (titular de dados pessoais) quem somos e como nós utilizamos suas informações pessoais, conforme estabelecido nesta Política;</span></li>
                            <li><span>Você tem permissão do indivíduo (titular de dados pessoais) para fornecer essas informações pessoais a nós e que podemos usá-las, conforme estabelecido nesta Política de Privacidade.</span></li>
                          </ul>

                          Podemos monitorar chamadas, e-mails, mensagens de texto e outras comunicações que mantemos com você. Quando você entrar em contato conosco, poderemos manter registro dessa comunicação e de qualquer informação fornecida durante essa ou qualquer comunicação subsequente.

                          Também coletamos, usamos e compartilhamos dados agregados, como dados estatísticos ou demográficos, para finalidades aderentes ao nosso propósito e objeto fundacional. Os Dados Agregados podem ser derivados de seus dados pessoais, mas não são considerados dados pessoais pela legislação, pois esses dados não revelam sua identidade direta ou indiretamente. Por exemplo, podemos agregar seus Dados de Uso para calcular a porcentagem de usuários que acessam um recurso de um site específico da Fundacred.

                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Marketing</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Gostaríamos de mantê-lo atualizado sobre nossos serviços relevantes por e-mail, SMS, mensagens por aplicativos, redes sociais e/ou telefone. Se pretendermos utilizar os seus dados para tais fins ou se pretendermos divulgar as suas informações a terceiros para tais fins, apenas o faremos com o seu consentimento. Seu consentimento será obtido especificamente no momento apropriado. Se você já tiver consentido e desejar que paremos de usar suas informações para esse fim ou qualquer outra finalidade, entre em contato conosco através das informações de contato na seção abaixo.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Natureza das informações pessoais</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Dados pessoais são quaisquer informações que possam identificar um indivíduo vivo. Nós coletamos informações pessoais como nome, número de inscrição no cadastro de pessoas físicas, número de inscrição no registro geral, endereço, e-mail, telefones, data de nascimento, sexo, estado civil, detalhes financeiros, detalhes de emprego e outros dados pessoais, dependendo da natureza do serviço que oferecemos e que foi buscado por você.
                          Podemos coletar, tratar e armazenar informações pessoais que podem ser compartilhadas com instituições de ensino, instituições financeiras, fornecedores e provedores de serviços terceirizados. Quando necessário, obteremos seu consentimento para o processamento de tais informações.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Compartilhamento de informação</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Forneceremos suas informações pessoais somente a terceiros quando tal transferência for uma parte necessária das atividades que assumimos, quando você nos der consentimento ou quando formos obrigados a fazê-lo por lei ou regulamento (por exemplo, quando a divulgação for necessária para fins tributários e fiscais).
                          <br/>
                          Também divulgaremos suas informações a prestadores de serviços contratados para realizar serviços em nosso nome. Tais serviços são contratualmente restritos ou para atender a obrigações legais.
                          <br/><br/>
                          Compartilhamos somente suas informações se estivermos satisfeitos com o fato de nossos parceiros ou fornecedores terem medidas suficientes para proteger suas informações da mesma maneira que fazemos.
                          Também podemos divulgar informações pessoais ao permitir auditorias internas e externas da entidade, inspeções regulatórias ou para investigar uma reclamação, suspeita ou fraude, ou ainda uma ameaça à segurança.
                          Nós nunca compartilhamos suas informações fora de nossa entidade para fins de marketing que não sigam nossas finalidades, propósito, missão e objeto fundacional.
                          Agora você está ciente de que podemos divulgar as informações fornecidas a outras partes relevantes para as finalidades descritas nesta Política de Privacidade.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Transferência de dados pessoais fora do Brasil</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Para fins de cópia de segurança de dados, pode ser necessário encaminhar seus dados para outros países, de acordo com a lei aplicável. Tomaremos medidas organizacionais, contratuais e legais para garantir que os dados pessoais sejam processados exclusivamente para a Fundacred.
                          Os recebedores dos seus dados pessoais (em caso de cópias de segurança) podem estar localizados fora do Brasil, onde as leis de proteção de dados podem não ser equivalentes à lei de proteção de dados do Brasil. Nesses casos, o recipiente será solicitado a tomar medidas para proteger seus dados de acordo com leis de proteção e privacidade de dados vigentes.
                          <br/><br/>
                          Ao nos fornecer suas informações, você concorda com a coleta, transferência internacional, armazenamento e processamento de suas informações. Essas transferências são regidas pela legislação brasileira atual ou por acordos de transferência de dados equivalentes para proteger a segurança e a confidencialidade das informações pessoais.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Por quanto tempo mantemos seus dados pessoais?</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Manteremos seus dados pessoais pelo tempo necessário para nos permitir fazer simulações, administrar seus contratos, oferecer renovação e responder a qualquer reclamação e prestação de contas (que pode surgir após o término do período de contratação e cumprimento dos seus contratos).
                          A Fundacred pode (para fins de auditoria, segurança, controle de fraudes e preservação de direitos) manter o histórico de registros de dados do usuário conforme o período estabelecido pela lei ou pelo padrão regulamentar.
                          Assim que decidirmos que não existe mais necessidade de armazenarmos os seus dados pessoais, eles serão eliminados de maneira segura e confidencial.
                          <br/>
                          <br/>
                          <b>PRINCÍPIOS DE PROTEÇÃO DE DADOS E PRIVACIDADE&nbsp;</b>
                          <br/>
                          <br/>
                          Cumpriremos a lei e os princípios de proteção de dados, o que significa que seus dados serão:
                          <br/>
                          <ul>
                            <li>Usados de forma legal, justa e transparente.</li>
                            <li>Recolhidos apenas para propósitos legítimos que explicamos claramente a você.</li>
                            <li>Relevantes para os propósitos que lhe informamos e limitados apenas a esses propósitos.</li>
                            <li>Precisos e mantidos atualizados.</li>
                            <li>Mantidos apenas o tempo necessário para os fins que lhes informamos.</li>
                            <li>Mantidos com segurança.</li>
                            <li>Não serão utilizados para fins discriminatórios, especialmente, quando processados para classificação de perfil, respeitadas as condições dos regulamentos e às legislações a que estamos submetidos.</li>
                          </ul>
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Segurança das informações confidenciais</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          O acesso às informações pessoais coletadas e armazenadas pela Fundacred é restrito aos profissionais autorizados ao uso direto dessas informações, e necessário à prestação de seus serviços, sendo limitado o uso para outras tarefas.
                          É exigido, também, de toda organização ou indivíduo contratado para a prestação de serviços de apoio, que sejam cumpridas a Política de Segurança da Informação e normas&nbsp; adotadas pela Fundacred.
                          <br/><br/>
                          Qualquer informação fornecida pelos usuários diretamente à FUNDACRED será coletada e guardada de acordo com rígidos padrões de segurança e confiabilidade, por protocolos padrão e tecnologia do setor, por si ou em servidores de empresas terceirizadas.
                          Todas as informações trafegadas entre o usuário e o PORTAL DO ESTUDANTE são criptografadas, impedindo o acesso via interceptação. Ainda que interceptados, dada a criptografia utilizada, os dados e documentos ficarão ilegíveis para o terceiro desautorizado.
                          O acesso às informações coletadas é restrito aos colaboradores e pessoas autorizadas, cumprindo a FUNDACRED com o previsto no art. 13 do Decreto nº 8.771/16.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Seus direitos de proteção de dados</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Você tem direitos legais sob as leis e regulamentos de proteção de dados do Brasil, resumidos da seguinte forma:
                          <ul>
                            <li><b>O direito de ser informado</b><span> sobre nossas atividades de processamento de dados, inclusive por meio de avisos de privacidade como este.</span></li>
                            <li><b>O direito de acessar</b><span> as informações pessoais que temos sobre você. Além de disponibilizá-las em nosso portal, você pode solicitar uma cópia dessas informações, fazendo uma solicitação de acesso por escrito para nós, conforme informação de contato na seção abaixo.</span></li>
                            <li><b>O direito de retificação. </b><span>Você deve nos pedir para corrigir quaisquer dados imprecisos ou incompletos.</span></li>
                            <li><b>O direito de apagar e restringir o processamento. </b><span>Você tem o direito de apagar seus dados pessoais e impedir o processamento, exceto quando tivermos outra base legal para processar suas informações, como por exemplo obrigação legal e legítimo interesse (respeitando sua prévia, explícita e legítima expectativa). Você deve ter em mente que, ao exercer esse direito, poderá prejudicar ou impedir nossa capacidade de fornecer nossos serviços que poderão ser de seu interesse e valiosos para você.</span></li>
                            <li><b>O direito à portabilidade de dados. </b><span>Além de disponibilizar a você em nosso portal para download, você pode solicitar a portabilidade de seus dados pessoais em um formato estruturado.</span></li>
                            <li><b>O direito de objetar. </b><span>Você tem direitos particulares em relação à tomada de decisões e perfis automatizados para reduzir o risco de que uma decisão potencialmente prejudicial seja tomada sem intervenção humana. Você pode se opor a que seus dados pessoais sejam usados ​​para propósitos de criação de perfil, marketing direto ou pesquisa.</span></li>
                          </ul>
                          Se você tiver alguma reclamação sobre seus direitos relacionados à privacidade e proteção de dados, por favor, utilize os dados da sessão abaixo para nos reportar.
                          <br/><br/>
                          <p><b>COMO ENTRAR EM CONTATO CONOSCO&nbsp;</b></p>
                          <br/>
                          Para quaisquer dúvidas ou preocupações relacionadas a esta Política de Privacidade ou às nossas práticas de Proteção de Dados e Privacidade, bem como para ter acesso aos seus dados tratados por nós, retirar seu consentimento ou para qualquer outra solicitação ou reclamação referente às informações que possuímos, entre em contato conosco em:&nbsp;
                          <br/><br/>
                          Av. Júlio de Castilhos, 44 – Térreo –&nbsp;Porto Alegre – RS – CEP 90030-130 –&nbsp;e-mail: privacidade<a href="mailto:dpo@fundacred.org.br">@fundacred.org.br</a>&nbsp;&nbsp;

                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Informações para outras fontes</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Podemos usar fontes públicas e privadas legais como o Cadastro Pessoas Físicas da Receita Federal e outras fontes de terceiros, tais como birôs de crédito, para obter informações sobre você, por exemplo, para verificar sua identidade ou verificar seu histórico de crédito ou restrições de crédito. Tais informações só serão obtidas de fontes respeitáveis ​​que operem de acordo com a legislação referente a privacidade e proteção de dados vigente.
                          Nós podemos usar suas informações de forma anônima, ou seja, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a você, para fins de pesquisa, análise estatística e de tendências, que podem incluir processos automatizados, os quais o classificam em um ou mais perfis. O perfil automático nos ajuda a entender, e prever as preferências do cliente e a melhorar os serviços que oferecemos, bem como avaliar quais serviços podem ser mais adequados para você.
                          <br/>
                          <br/>
                          <b>TERMOS DE USO DO SITE</b>
                          <br/>
                          <br/>
                          <b>LINKS DE TERCEIROS</b>
                          <br/>
                          <br/>
                          Este site pode incluir links para sites, plug-ins e aplicativos de terceiros. Clicar nesses links ou ativar essas conexões podem permitir que terceiros coletem ou compartilhem dados sobre você. Nós não controlamos esses sites de terceiros e não somos responsáveis ​​por suas declarações de privacidade. Ao sair do nosso site, incentivamos você a ler o aviso de privacidade de todos os sites que visitar.
                          <br/>
                          <br/>
                          <b>E se você não fornecer seus dados pessoais?</b>
                          <br/>
                          <br/>
                          Sempre que precisarmos coletar dados pessoais por obrigação legal, ou sob os termos de um contrato que temos com você e você não fornecer esses dados quando solicitado, podemos não ser capazes de executar o contrato que temos ou estamos tentando celebrar com você. Neste caso, podemos ter que cancelar a prestação de serviços que você tem conosco, mas iremos notificá-lo se este for o caso no momento.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>


          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={8}>
              <h2>Política de cookies</h2>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={8}>
              <Card className="tabela-privacidade">
                <CardContent>
                  <Grid cotainer className="coluna-lg">
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">O que é um cookie?</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Um cookie é um dado que o nosso website solicita ao seu navegador para armazenar no seu computador (ou outro dispositivo conectado à internet, como smartphones ou tablets). O cookie permite que o nosso website “recorde” suas ações ou preferências ao longo de um determinado período, ele pode ter diversas utilidades desde identificar suas preferências para proporcionar um conteúdo de acordo com suas preferências, até para que sua navegação seja mais fluida e facilitada.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Por que usamos cookies?</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Usamos cookies para aprender como você interage com o nosso conteúdo e assim melhorar sua experiência ao visitar o nosso website. Por exemplo, alguns cookies recordam seu usuário para que consiga acessar nosso portal mais rapidamente, ou para que você visualize vídeos do YouTube, por exemplo. Também usamos cookies de rastreamento de geolocalização, que servem para que possamos lhe apresentar quais são as instituições de ensino mais próximas de você. Além disso, os cookies permitem que ofereçamos um conteúdo específico, tais como vídeos no(s) nosso(s) website(s). Podemos empregar o que aprendemos sobre o seu comportamento no(s) nosso(s) website(s) para oferecer anúncios direcionados em website(s) de terceiros em um esforço para “reapresentar” nossos produtos e serviços para você.
                          <br/><br/>
                          Além disso, os cookies também podem ser utilizados para ajudar a acelerar suas atividades e experiências futuras em nossas páginas. Igualmente, utilizamos cookies para compilar estatísticas anônimas e agregadas que nos permitem entender como os usuários utilizam nossas páginas e nos ajudar a melhorar nossas estruturas e conteúdos para deixá-los mais atrativos a você. Neste caso não iremos identificá-lo pessoalmente através dessas informações.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Quais os tipos de Cookies que utilizamos</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography variant="body1">
                          Utilizamos duas categoria de Cookies, os primários e os de terceiros.
                          <br/><br/>
                          Os primários são aqueles emitidos pelo próprio domínio fundacred.org.br  que usualmente são utilizados para identificar preferências, traçar padrões de uso e também para facilitar a experiência do usuário em nosso site.
                          <br/><br/>
                          Cookies de terceiros pertencem e são administrados por outras empresas, como provedores de serviços, ao exemplo de um link do Facebook ou um vídeo incorporado do YouTube. Estes vídeos ou links (e qualquer outro conteúdo de fornecedores terceirizados) podem conter cookies de terceiros, e nós recomendamos consultar as políticas de privacidade destes terceiros nos seus websites para informações relativas a sua utilização de cookies, bem como no caso de querer desativar eles.
                          <ul>
                            <li>Cookies da Sessão</li>
                          </ul>
                          Os cookies da sessão são temporários e utilizados para lembrar de você durante o curso da sua visita ao website, e eles são excluídos quando você fecha o navegador.
                          <ul>
                            <li>Cookies Persistentes</li>
                          </ul>
                          Cookies persistentes são utilizados para lembrar suas preferências do website e permanecem no seu desktop ou dispositivo móvel mesmo depois de você fechar o seu navegador ou ao efetuar uma reinicialização. Usamos estes cookies para analisar o comportamento do usuário e estabelecer padrões, de modo que possamos melhorar a funcionalidade para você e outros que visitam o(s) nosso(s) website(s). Estes cookies também permitem que possamos oferecer anúncios segmentados e medir a eficácia do nosso site e a funcionalidade de tais anúncios.
                          <ul>
                            <li>Cookies de Desempenho</li>
                          </ul>
                          Utilizamos cookies do Google Analytics que servem para nos ajudar a melhorar nosso website, coletando e relatando informações sobre como você os usa, isso nos ajuda a melhorar a maneira como nosso site funciona, garantindo que os usuários encontrem o que procuram com mais facilidade. Estes cookies coletam informações de forma anônima, ou seja, você não será identificado.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="label">Como faço para alterar minhas configurações de cookies?</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Você pode apagar os cookies a qualquer momento e, como alternativa, a maioria dos navegadores da web permite algum controle dos cookies por meio das configurações do próprio navegador.
                          Descubra como gerenciar cookies em navegadores populares:
                          <ul>
                            <li><a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</a></li>
                            <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Microsoft borda</a></li>
                            <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
                            <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer</a></li>
                            <li><a href="https://www.opera.com/help/tutorials/security/privacy/">Opera</a></li>
                            <li><a href="https://support.apple.com/en-gb/safari">Apple Safari</a></li>
                          </ul>
                          Para encontrar informações relacionadas a outros navegadores, visite o site do desenvolvedor do navegador.
                          Para desativar o rastreamento do Google Analytics em todos os sites, visite <a href="http://tools.google.com/dlpage/gaoptout.">http://tools.google.com/dlpage/gaoptout.</a>
                          &nbsp;Mas lembre-se, desabilitar os cookies poderá prejudicar a sua experiência de navegação em nosso site. Por exemplo, talvez você não possa visitar certas áreas do nosso site ou talvez não receba informações personalizadas conforme seus interesses.
                        </Typography>
                        <Paper elevation={0}>
                          <Table className="tabela-cookies">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left"><p className="texto-label">Cookie</p></TableCell>
                                <TableCell align="left"><p className="texto-label" >Finalidade</p></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell className="row-azul border" component="th" scope="row"><p className="nome">Facebook</p></TableCell>
                                <TableCell className="row-azul" align="left">
                                  <p className="descricao">Permite que você compartilhe comentários/avaliações/páginas/favoritos, bem como o ajuda a acessar nossa plataforma de forma mais fácil e ágil por meio do seu login social.</p>
                                  <p className="descricao"><a href="https://www.facebook.com/full_data_use_policy">Para mais informações sobre a política de privacidade do Facebook clique aqui.</a></p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="row-cinza border" component="th" scope="row"><p className="nome">Google Analytics</p></TableCell>
                                <TableCell className="row-cinza" align="left">
                                  <p className="descricao">Esses cookies são usados ​​para coletar informações sobre como os visitantes usam nosso site. Usamos as informações para compilar relatórios e nos ajudar a melhorar o site. Os cookies coletam informações de forma anônima, incluindo o número de visitantes do site e do blog, de onde os visitantes chegaram ao site e das páginas visitadas.</p>
                                  <p className="descricao"><a href="https://support.google.com/analytics/answer/6004245">Leia a visão geral do Google sobre privacidade e proteção de dados</a></p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="row-azul border" component="th" scope="row"><p className="nome">Google Ads</p></TableCell>
                                <TableCell className="row-azul" align="left">
                                  <p className="descricao">Estes cookies ajudam identificar o tipo de anúncio que você gostaria de ver com base nas suas preferências, ofertando nossos serviços em outros sites. </p>
                                  <p className="descricao"><a href="https://policies.google.com/technologies/types?hl=pt-BR">Leia a visão geral do Google sobre privacidade e proteção de dados</a></p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="row-cinza border" component="th" scope="row"><p className="nome" >YouTube</p></TableCell>
                                <TableCell className="row-cinza" align="left">
                                  <p className="descricao">Incorporamos vídeos de nosso canal oficial do YouTube usando o modo de privacidade aprimorado do YouTube. Esse modo pode definir cookies no seu computador assim que você clicar no player de vídeo do YouTube, mas o YouTube não armazenará informações de cookies de identificação pessoal para reproduções de vídeos incorporados usando o modo de privacidade aprimorada.</p>
                                  <p className="descricao">Leia mais na <a href="http://www.google.com/support/youtube/bin/answer.py?hl=en-GB&answer=171780">página de informações sobre vídeos incorporados</a> no  <a href="http://www.google.com/support/youtube/bin/answer.py?hl=en-GB&answer=171780">YouTube</a>.</p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="row-azul border" component="th" scope="row"><p className="nome">Google Accounts</p></TableCell>
                                <TableCell className="row-azul" align="left">
                                  <p className="descricao">Esses cookies são utilizados para criar uma solicitação de crédito utilizando sua conta da Google.</p>
                                  <p className="descricao"><a href="https://policies.google.com/technologies/types?hl=pt-BR">Leia a visão geral do Google sobre privacidade e proteção de dados</a></p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="row-cinza border" component="th" scope="row"><p className="nome">Hotjar</p></TableCell>
                                <TableCell className="row-cinza" align="left">
                                  <p className="descricao">Esses cookies são definidos quando o cliente chega à página pela primeira vez com o script Hotjar. É usado para manter o ID do usuário aleatório, exclusivo para esse site no navegador. Isso garante que o comportamento nas visitas subseqüentes ao mesmo site seja atribuído ao mesmo ID do usuário.</p>
                                  <p className="descricao"><a href="https://www.hotjar.com/legal/compliance/opt-out">Veja mais sobre a Hotjar clique aqui.</a></p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="row-azul border" component="th" scope="row"><p className="nome">Doubleclick.net</p></TableCell>
                                <TableCell className="row-azul" align="left">
                                  <p className="descricao">Estes cookies ajudam identificar o tipo de anúncio que você gostaria de ver com base nas suas preferências, ofertando nossos serviços em outros sites.</p>
                                  <p className="descricao"><a href="https://policies.google.com/technologies/types?hl=pt-BR">Para saber mais sobre o Doubleclick.net clique aqui.</a></p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="row-cinza border" component="th" scope="row"><p className="nome">Zendesk</p></TableCell>
                                <TableCell className="row-cinza" align="left">
                                  <p className="descricao">Esses cookies guardam suas informações nas nossas plataformas de comunicação, mantendo um histórico das conversas para que possamos melhor lhe auxiliar em atendimentos futuros.</p>
                                  <p className="descricao"><a href="https://www.zendesk.com.br/company/customers-partners/cookie-policy/">Mais informações sobre a Zendesk clique aqui.</a></p>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="row-azul border" component="th" scope="row"><p className="nome">Acoustic Campaign</p></TableCell>
                                <TableCell className="row-azul" align="left">
                                  <p className="descricao">A função de Web Tracking rastreia comportamentos de visitantes em páginas web fora da plataforma Acoustic Campaign para serem utilizados dentro da plataforma. Isso permite que você acompanhe o comportamento de um contato enquanto ele navega pelos sites, registrando ações como visitas, cliques em hiperlinks, downloads de documentos e reproduções de mídia.</p>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Paper>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

        </Container>
      </section>
    );
  }

}

export default PoliticaPrivacidade;
