import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import BlocoComoFuncionaMob from './BlocoComoFuncionaMob';
import BlocoComoFuncionaDesk from './BlocoComoFuncionaDesk';
import '../assets/css/Como-Funciona.css';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import DefaultContext from '../themes/DefaultContext';

function ComoFunciona(props) {
	const isDesk = useMediaQuery('(min-width:960px)');
	return (
		<DefaultContext.Consumer>
			{({theme})=> (
				<section id="como_funciona" className="como-funciona bg-primary p-relative">
					<Container maxWidth="lg">
						<Grid container>
							<Grid item xs={12}>
								<h2>Como Funciona?</h2>
							</Grid>
						</Grid>

						{
							(isDesk) ?
							<BlocoComoFuncionaDesk {...props} />:
							<BlocoComoFuncionaMob {...props} />

						}

					</Container>
				</section>
			)}
		</DefaultContext.Consumer>
	);
}

export default ComoFunciona;