import React, { useState } from 'react';
import Accordion from '../../Accordion';
import ColumnLeft from '../../ColumnLeft';
import ColumnVideo from '../../ColumnVideo';
import ColumnTop from '../../ColumnTop';
import Mark from '../../Mark';
import ImgLogoFundacred from '../../../assets/img/logo-fundacred.png'

import { Container, Background, Center, SectionList, Titulo, IconBiPlusMedical, Footer, WapperFundacred, Main, ButtonDiv } from './styles';
import Button from 'components/Button';
import IESolicitarContatoModal from 'modals/IESolicitarContatoModal';

const listaPerguntas = [
    { title: "É necessário ofertar um número mínimo de vagas?", text: "A instituição de ensino tem a autonomia de ofertar quantas vagas desejar, sem número mínimo." },
    { title: "A parceria inclui o material de divulgação do +MAISACESSO?", text: "Sim. A estrutura de Marketing da Fundacred dará apoio na criação de peças digitais de acordo com a identidade visual da instituição." },
    { title: "Como funciona o envio de valores para confecção do contrato?", text: "É realizada uma automação com regras de concessão definidas pela instituição, possibilitando a aprovação automática dos estudantes que atenderem os critérios." },
    { title: "E os estudantes que não atenderem os critérios?", text: "Os estudantes serão direcionados para análise da instituição para que a situação financeira seja avaliada e, se for o caso, a aprovação manual seja feita." },
    { title: "Como a instituição sabe que o contrato foi assinado?", text: "No Portal da IE. Nele está todo o passo a passo, da solicitação até a assinatura do contrato, além de cópia do contrato assinado pelo estudante." },
    { title: "Como a instituição pode acompanhar a gestão do crédito?", text: "A Instituição de Ensino pode acompanhar pelo Portal da IE. Todas as etapas da solicitação podem ser visualizadas. Além disso, é possível realizar pesquisas, verificar os documentos, estudantes aprovados, status de contratos e relatórios." },
    { title: "Em quanto tempo o contrato é disponibilizado?", text: "O contrato fica disponível 24 horas úteis após o estudante concluir a solicitação e ser aprovado." },
    { title: "Menores de 18 anos podem solicitar?", text: "Sim, mas apenas se forem maiores de 16 anos. Para estudantes menores, a solicitação precisa ser feita pelo responsável financeiro." },
    { title: "Como é feita a assinatura do contrato?", text: "A assinatura é feita digitalmente, via token, pelo celular ou computador." },
    { title: "A renovação do crédito é obrigatória para a IE?", text: "Quem decide sobre a renovação do crédito é a instituição de ensino." },
    { title: "Os contratos dos estudantes serão atualizados?", text: "Sim. O valor contratado será atualizado mensalmente pelo INPC, considerando indicadores positivos (maiores que zero), da concessão até o mês de restituição de cada parcela." },
    { title: "É possível migrar do +MAISACESSO para o CredIES?", text: "Sim. O estudante pode migrar para o CredIES no momento que preferir, desde que cumpra os requisitos do programa." },
    { title: "Após a migração, as taxas reduzem desde o primeiro contrato?", text: "Sim. As taxas são reduzidas desde que o estudante entregue os contratos." },
    { title: "Quantos fiadores o estudante pode apresentar ao migrar para o CredIES, ou outro programa?", text: "O estudante pode indicar até 2 fiadores." },
];

const LandingIE: React.FC = () => {
    const [modal, setModal] = useState(false);

    return (
        <Container>
            <Background>
                <Main>
                    <ColumnTop />
                    <Center>
                        <ColumnLeft />
                        <ColumnVideo />
                    </Center>
                    <ButtonDiv>
                        <Button type="button" onClick={(e) => setModal(true)}>SOLICITAR CONTATO</Button>
                    </ButtonDiv>
                </Main>
            </Background>
           
            <SectionList>
                <Titulo>
                    <IconBiPlusMedical />
                    <p>Dúvidas frequentes</p>
                </Titulo>
                {
                    listaPerguntas.map((lista, index) =>{
                         return(
                             <Accordion 
                                 key={index}
                                 id={index + ''}
                                 title={lista.title}    
                                 text={lista.text}
                             />
                         )
                    })
                }
                
            </SectionList>
            <Footer>
                <Mark />

                <p>Dúvidas? Entre em contato por <a href="mailto:novosnegocios@fundacred.org.br">novosnegocios@fundacred.org.br</a></p>
                <WapperFundacred>
                    <p>Uma solução </p> <img src={ImgLogoFundacred} alt="logo-fundacred" />
                </WapperFundacred>
            </Footer>
            <IESolicitarContatoModal isOpen={modal} onClose={(e) => setModal(false)} />
        </Container>
    )
}

export default LandingIE;