import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import '../assets/css/Header.css';
import MenuMobile from './MenuMobile';
import MenuLinks from './MenuLinks';
import ButtonGA from './UI/ButtonGA';

import LogoHeader from '../assets/img/logo-header.png';
import DefaultContext from '../themes/DefaultContext';

function Header(props) {
  const [menuMobile, showMenuMobile] = React.useState(false);
  const context = useContext(DefaultContext);

  const handleShowMenuMobile = (e) => {
    showMenuMobile(true);
  };

  const handleHideMenuMobile = (e) => {
    showMenuMobile(false);
  };

  return (
    <header>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={2} className="no-desk">
            <Button className="btn btn-menu" onClick={handleShowMenuMobile}>
              <div className="barra-grande"></div>
              <div className="barra-pequena"></div>
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <h1>
              <a href="/">
                <img src={LogoHeader} className="fluid" alt="Mais Acesso" />
              </a>
            </h1>
          </Grid>

          <Grid item xs={8} md={8} className="no-mobile">
            <nav className="menu-desk">
              <MenuLinks />
            </nav>
          </Grid>
          <Grid item xs={6} sm={6} md={3} className="header-buttons">
            <ButtonGA color="primary" className="f-right btn btn-entrar" onClick={context.redirectToWebapp}>
              Entrar
            </ButtonGA>
          </Grid>
        </Grid>
      </Container>
      <MenuMobile in={menuMobile} onClose={handleHideMenuMobile} />
    </header>
  );
}

export default Header;
