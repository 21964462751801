import React, { useEffect } from 'react';

const BlockBackButton = (props) => {

    useEffect(() => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = () => {
            window.history.pushState(null, "", window.location.href);
            props.onBack();
        };

        return () => window.onpopstate = () => { }
    }, []);

    return <></>;
};

export default BlockBackButton;