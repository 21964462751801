import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Transition from 'react-transition-group/Transition';
import BlocoCurso from './BlocoCurso';
import BlocoFormatura from './BlocoFormatura';

import LazyLoad from 'react-lazyload';
import DefaultContext from '../themes/DefaultContext';

function BlocoComoFuncionaMob(props) {
	const [comoFunciona, showComoFunciona] = React.useState(true);
    
    const handleShowComoFunciona = e => {
        showComoFunciona(true);
    } 
    const handleHideComoFunciona = e => {
        showComoFunciona(false);
    }

    const duration = 300;

    const defaultStyle = {
        transition: `opacity 300ms ease-in-out`,
        opacity: 0,
    }
    
    const transitionStyles = {
        entering: { opacity: 0, display: 'none' },
        entered:  { opacity: 1 , display: 'block'},
        exited:  { opacity: 0, display: 'none'},
	};

	return (
		<DefaultContext.Consumer>
			{({theme})=> (
				<Fragment>
					<Grid container>
						<Grid item xs={12}>
							<ButtonGroup fullWidth className="btn-group-como-funciona">
								<Button className={(comoFunciona)?"btn active":"btn"} onClick={handleShowComoFunciona}>
									Antes
								</Button>
								<Button className={(!comoFunciona)?"btn active":"btn"} onClick={handleHideComoFunciona}>
									Depois
								</Button>
							</ButtonGroup>
						</Grid>
					</Grid>

					<Transition in={comoFunciona} timeout={duration}>
						{(state) => (
							<div style={{
								...defaultStyle,
								...transitionStyles[state]
							}}>
								<BlocoFormatura />
								<LazyLoad once offset={200}>
									<img src={theme.comoFunciona.imgModelo} className="modelo-como-funciona" alt={theme.comoFunciona.imgAltModelo} />
								</LazyLoad>
							</div>
						)}
					</Transition>

					<Transition in={!comoFunciona} timeout={duration}>
						{(state) => (
							<div style={{
								...defaultStyle,
								...transitionStyles[state]
							}}>
								<BlocoCurso />
								<LazyLoad once offset={200}>
									<img src={theme.comoFunciona.imgModelo} className="modelo-como-funciona-mod" alt={theme.comoFunciona.imgAltModelo} />
								</LazyLoad>
							</div>
						)}
					</Transition>
				</Fragment>
			)}
		</DefaultContext.Consumer>
	);
}

export default BlocoComoFuncionaMob;