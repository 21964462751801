import React, { useContext } from 'react';
import PoliticaPrivacidade from '../PoliticaPrivacidade';
import DefaultContext from '../../themes/DefaultContext';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import BotoesSociais from '../BotoesSociais';

function Politica() {
  const { theme } = useContext(DefaultContext);

  return (
    <>
      <section>
        <Helmet>
          <html data-theme={theme.name}></html>
          <title>{theme.displayName} - O melhor crédito educacional - Fundacred</title>
        </Helmet>
        <Header imgSolucao={theme.imgSolucao} />
        <PoliticaPrivacidade />
      </section>
      <Footer />
      <BotoesSociais />
    </>
  );
}

export default Politica;
