import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import formatMoney from './../../../utils/format-money';
import ThumbSlider from './ThumbSlider';

const useStyles = makeStyles({
    root: {
        '& .MuiInput-underline input': {
            color: '#0064E6'
        },
        '& .MuiInput-underline:after, & .MuiInput-underline:before': {
            borderBottom: '0 !important'
        },
        '& .MuiFormHelperText-root': {
            marginTop: 0,
            marginBottom: '6px'
        }
    },
    slider: {
        paddingTop: 0,
        borderRadius: 1,
        color: '#DCDEE0',
    },
    track: {
        color: '#0064E6',
        borderRadius: 1
    },
    thumb: {
        height: 24,
        width: 44,
        backgroundColor: '#fff',
        color: '#0064E6',
        border: 0,
        marginTop: -12,
        marginLeft: -13,
        '& .outer': {
            height: 12,
            width: 12,
            backgroundColor: '#0064E6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%'        
        },
        '& .inner': {
            height: 4,
            width: 4,
            backgroundColor: '#FFFFFF',
            borderRadius: '50%'
        },
        '& .arrow-icon':{
            width: 9,
            opacity: 0.6,
            margin: 2
        }
    },
});

const CurrencyInputSlider = (props) => {
    const [value, setValue] = useState(props.value);
    const [formatted, setFormatted] = useState(props.value ? 'R$' + formatMoney(props.value) : '');
    const [showSlider, setShowSlider] = useState(false);
    const [clickedOutside, setClickedOutside] = useState(false);
    const myRef = useRef();
    const classes = useStyles();

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutside(true);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    function inputChangedHandler(event) {
        const val = event ? event.target.value : 0;
        sliderChangedHandler(null, val);
    }

    function inputFocusHandler() {
        setShowSlider(true);
        setClickedOutside(false);
    }

    function sliderChangedHandler(event, val) {
        const numberValue = (typeof val != 'number') ? (parseInt(val.replace(/\D/g, '')) / 100) : val;
        setValue(numberValue);

        const valid = !props.minValue || numberValue >= props.minValue;
        props.changed(numberValue, valid);
        
        const formattedVal = formatMoney(val) || '0,00';
        const currency = 'R$ ' + formattedVal;
        setFormatted(currency);
    }

    return (
        <div ref={myRef}>
            <TextField
                label={props.label}
                classes={{
                    root: (showSlider && !clickedOutside) ? classes.root : ''
                }}
                margin="dense"
                fullWidth
                type="tel"
                value={formatted}
                error={props.error}
                helperText={props.error ? `O valor mínimo para simular é de R$ ${formatMoney(props.minValue)}.` : ''}
                onChange={inputChangedHandler}
                onFocus={inputFocusHandler}
            />

            {showSlider && !clickedOutside && (
                <Slider
                    classes={{
                        root: classes.slider,
                        track: classes.track,
                        thumb: classes.thumb
                    }}
                    value={value || 0}
                    max={10000}
                    step={1}
                    ThumbComponent={ThumbSlider}
                    onChange={sliderChangedHandler}
                    aria-labelledby="input-slider"
                />
            )}
        </div>
    );
};

export default CurrencyInputSlider;
