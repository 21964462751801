import React from 'react';
import { Container} from './styles';
import logo from '../../assets/img/logo-header.png'
const Mark: React.FC = () =>{


    return(
        <Container>
            <img src={logo} alt=""/>
        </Container>
    )
}

export default Mark;