import { TextField } from '@material-ui/core';
import React, { useState } from 'react'
import { nome } from '../../utils/mask'
import {Container } from './styles';

interface InputProps{

    onChange:(e:any) => void, 
    shouldValidate:boolean;
     label?: string;
     nameId?: string;
     mask: "nome";
     disabled:boolean;
 }

const Input: React.FC<InputProps> = ({onChange, mask ,shouldValidate, nameId, label, disabled}) => {

    const [valid, setValid] = useState(false);
    const changeHandler = (event: any) => {
        if(mask == 'nome'){
           let valueMask =  nome(event);
           event.currentTarget.value = valueMask
           setValid(true)
           onChange(event);
        }        
    }

    return (
        <Container>
            <TextField
                label={label}
                fullWidth
                type="text"
                name={nameId}
                error={ shouldValidate && !valid }
                helperText={ shouldValidate && !valid ? `digite um nome valido ${nameId}` : ''}
                onChange={changeHandler}
                disabled={disabled}
            />
        </Container>
    )
}

export default Input;