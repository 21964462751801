import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import img_background from '../../assets/img/img-background-modal-form.png';

export const OverLay = styled(Dialog)`

    .MuiPaper-root{
        border-radius:2rem;
        box-shadow:2px 2px 10px var(--color-cinza);
        overflow: hidden;

        @media(max-width:600px){        
            height: calc(100% - 40px);
            max-height: calc(100% - 40px);
            margin: 20px;
        }        

        .close-button{
            color: var(--color-cinza);
        }
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    background: var(--color-background-form);
    min-height: 79vh;
        
    @media(max-width:900px){        
        padding:20px;
        height: 100%;
    }
`;

export const Image = styled.div`
    background-image:url(${img_background});
    background-repeat:no-repeat;
    background-size: cover;
    background-position: top right;  
    width: 42%;  

    @media(max-width:900px){        
        display: none;
    }
`;

export const FormContainer = styled.div`
    display:flex;
    flex-direction:column;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    flex: 1;
    box-sizing: border-box;
    overflow-y: auto;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-right: 3rem;

    @media(max-width:900px){
        padding-right: 0;
    }
`;

export const Titulo = styled.h3`
    color:var(--secondaryTextColor);
    font-size: 1.6rem;    
    font-family: 'Gotham Bold';
    margin: 0;

    @media(max-width:600px){
        margin-bottom:1rem;
        line-height:1.1;
    }

    &.center{
        display: flex;
        flex: 1;
        align-items: center;
    }
`;

export const Subtitulo = styled.h5`
    color: var(--firstTextColor);
    font-size: 1.2rem;
    font-family: 'Gotham Bold';
    margin: 1rem 0;

    @media(max-width:600px){
        margin-bottom:1rem;
        line-height:1.1;
    }

    &.center{
        display: flex;
        flex: 1;
        align-items: center;
    }
`;

export const SubtituloEstudante = styled.h6`
    color: var(--firstTextColor);
    font-size: 1rem;
    font-family: 'Gotham Bold';
    margin: 1rem 0;

    @media(max-width:600px){
        margin-bottom:1rem;
        line-height:1.1;
    }

    &.center{
        display: flex;
        flex: 1;
        align-items: center;
    }
`;

export const Selected = styled.div`
    width:60%;
    padding-top: 0.5rem;

    @media(max-width:600px){
        width: 100%;
    }
`;

export const ButtonFrom = styled.div`    
    margin-top:1.4rem;
    
    >button{        
        font-family: 'Gotham Bold';
        border: none;
        padding: 1rem 2.5rem;
        border-radius: 5rem;
        background:var( --color-blue) ;
        color: var(--btnDefaultTextColor);
        font-size:1rem;
        cursor:pointer;
        outline: none;

        &:hover{
            background: var(--depoimentoPrimaryColor);
        }

        &:disabled{
            opacity: .5;
            cursor: wait;
        }
    }

    @media(max-width:600px){
        margin-top: auto;

        button{
            padding: 1rem 1.5rem;
        }
    }

    span{
        color: #f44336;
        display: block;
        font-family: 'Gotham Bold';
        font-size: 0.8rem;
        padding-top: 0.5rem;
    }
`;
export const ButtonSolicitar = styled.div`    
    margin-top:2.2rem;
    
    >a{        
        font-family: 'Gotham Bold';
        border: none;
        padding: 1rem 2.5rem;
        border-radius: 5rem;
        background:var( --color-blue) ;
        color: var(--btnDefaultTextColor);
        font-size:1rem;
        cursor:pointer;
        outline: none;
        text-decoration: none;

        &:hover{
            background: var(--depoimentoPrimaryColor);
        }

        &:disabled{
            opacity: .5;
            cursor: wait;
        }
    }

    @media(max-width:600px){
        margin-top: auto;

        a{
            padding: 1rem 1.5rem;
        }
    }

    span{
        color: #f44336;
        display: block;
        font-family: 'Gotham Bold';
        font-size: 0.8rem;
        padding-top: 0.5rem;
    }
`;