import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios';
import Input from '../../components/Input';
import { Container, Selected, Titulo, Subtitulo, Image, FormContainer, Form, ButtonFrom, ButtonSolicitar, OverLay } from './styles';
import DropdownMobileDesktop from 'components/UI/forms/DropdownMobileDesktop';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputTel from 'components/UI/forms/InputTel';
import InputEmail from 'components/UI/forms/InputEmail';
import * as simuladorService from 'utils/simulador-service';

interface InterfaceInput {
    nome?: string;
    instituicao?: string;
    estado?: InterfaceOption;
    email:string;
    telefone:string;
}

interface InterfaceOption {
    value: string;
    label: string;
}
interface ModalProps {
    isOpen: boolean;
    onClose: any;
}

const IESolicitarContatoModal: React.FC<ModalProps> = (props) => {
    const [firstSubmit, setFirstSubmit] = useState(false);
    const [contato, setContato] = useState<InterfaceInput>({} as InterfaceInput)
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showError, setShowError] = useState(false);
    const [tipoUsuario, setTipoUsuario] = useState('');

    const estados = simuladorService.getAllEstados();

    const onContatoChanged = (name: string, value: any) => {
        setContato({
            ...contato,
            [name]: value
        })
    };

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        onContatoChanged(e.currentTarget.name, e.currentTarget.value);
    }

    const closeModal = () => {
        setShowMessage(false);
        setButtonDisabled(false);
        setShowError(false);
        setContato({} as InterfaceInput)
        props.onClose();
    }

    const handleCreateContato = async (e: FormEvent) => {
        e.preventDefault();
        setFirstSubmit(true);

        if (!contato.nome || !contato.instituicao || !contato.estado || !contato.email || !contato.telefone) {
            return false;
        }

        const email = {
            paraVariaveis: [{
                email: process.env.REACT_APP_EMAIL_CONTATO,
                keys: [
                    {
                        key: "--nome--",
                        value: contato.nome
                    },
                    {
                        key: "--ie--",
                        value: contato.instituicao
                    },
                    {
                        key: "--estado--",
                        value: contato.estado.label
                    },
                    {
                        key: "--email--",
                        value: contato.email
                    },
                    {
                        key: "--telefone--",
                        value: contato.telefone
                    }
                
                
                ]
            }],
            assunto: "Contato - Landing IES MA",
            html: "Nome: --nome--<br/>Instituição: --ie--<br/>Estado: --estado--<br/>Email: --email--<br/>Telefone: --telefone--<br/>",
            remetente: "mailer@fundacred.org.br",
            texto: "Contato - Landing IES MA",
            categorias: ["ContatoIesMA"]
        };
        setButtonDisabled(true);
        setShowError(false);
        try {
            await axios.post(process.env.REACT_APP_COMUNICACAO_API_BASEURL + '/email/enviar', email);
            setShowMessage(true);
        } catch (err) {
            console.log(err);
            setButtonDisabled(false);
            setShowError(true);
        }
    }
    const euSouArr = [{label:"Estudante", value:"estudante"},{label:"Instituição de Ensino", value:"instituicaoEnsino"}];
    return (
        <OverLay
            open={props.isOpen}
            onClose={closeModal}
            maxWidth="xl"
        >
            <Container>
                <Image></Image>
                <FormContainer>
                    <header>
                        <IconButton edge="end"
                            onClick={closeModal}
                            aria-label="Fechar"
                            className="close-button">
                            <CloseIcon />
                        </IconButton>
                    </header>

                    {showMessage ?
                        <Titulo className="center">Mensagem enviada com sucesso, um de nossos consultores entrará em contato com vocês em breve!</Titulo>
                        :
                        <Form onSubmit={handleCreateContato}>
                            <Titulo>Se você trabalha em uma Instituição de Ensino e tem interesse em conhecer o +MAISACESSO, preencha o formulário abaixo.</Titulo>
                            <Subtitulo>Caso seja estudante com interesse em solicitar o +MAISACESSO, <a href="/graduacao/">clique aqui</a>.</Subtitulo>

                            <Selected>
                                <DropdownMobileDesktop
                                shouldValidate={firstSubmit && !contato.nome}
                                label="Eu sou"
                                inputValue={euSouArr.find(item => item.value === tipoUsuario)?.label}
                                items={euSouArr}
                                onChange={(selected) => setTipoUsuario(selected.value)}
                                errorText="Selecione quem você é"
                                />
                            </Selected>
                            {tipoUsuario != 'estudante' &&
                            <>
                                <Input
                                    disabled={tipoUsuario === 'estudante'}
                                    shouldValidate={firstSubmit && !contato.nome}
                                    label="Qual seu nome?"
                                    nameId="nome"
                                    mask='nome'
                                    onChange={handleInputChange}
                                />
                                                        
                                <Input
                                    disabled={tipoUsuario === 'estudante'}
                                    shouldValidate={firstSubmit && !contato.instituicao}
                                    label="Qual sua instituição?"
                                    nameId="instituicao"
                                    mask='nome'
                                    onChange={handleInputChange}
                                />
                            
                                <div style={{paddingTop:"0.8rem"}}>

                                    <InputEmail 
                                        disabled={tipoUsuario === 'estudante'}
                                        onChange={(e) => onContatoChanged("email", e.value)}
                                        shouldValidate={firstSubmit}
                                    />
                                </div>
                            
                                <InputTel
                                    disabled={tipoUsuario === 'estudante'}
                                    onChange={(e) => onContatoChanged("telefone", e) }
                                    shouldValidate={firstSubmit}
                                />
                            
                                <Selected>
                                    <DropdownMobileDesktop
                                        disabled={tipoUsuario === 'estudante'}
                                        label="Qual o Estado de sua instituição?"
                                        helperText="Digite ou selecione"
                                        items={estados}
                                        inputValue={contato.estado ? contato.estado.label : ''}
                                        onChange={(selected) => onContatoChanged('estado', selected)}
                                        error={firstSubmit && !contato.estado}
                                        errorText="Selecione um estado" />
                                </Selected>
                            
                                <ButtonFrom>
                                    <button type="submit" disabled={buttonDisabled}>ENVIAR FORMULÁRIO</button>
                                    {showError && <span className="error">Ocorreu um erro ao enviar os dados. Por favor tente novamente.</span>}
                                </ButtonFrom>
                            </>
                            }
                            {tipoUsuario === 'estudante' &&
                                <ButtonSolicitar>
                                    <a href="/graduacao/">Ver simulador</a>
                                </ButtonSolicitar>
                            }
                        </Form>
                    }
                </FormContainer>
            </Container>
        </OverLay>
    );
}
export default IESolicitarContatoModal;