import React from 'react';
import TextField from '@material-ui/core/TextField';

const mask = (value) => {
    let val = value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d)/, '($1') // primeiro parentes
        .replace(/(\d{2})(\d)/, '$1) $2'); // segundo parenteses

    if (value.replace(/\D/g, '').length <= 10) {
        return val
            .replace(/(\d{4})(\d)/, '$1-$2')
    }
    return val
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4}).+?$/, '$1')
}

class InputTel extends React.Component {
    minLen = 14;
    constructor(props) {
        super(props);
        this.state = {
            val: this.props.val ? mask(this.props.val) : '',
            error: true,
            errorText: 'O telefone é obrigatório'
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        if(this.props.val){
            this.handlePreset(this.props.val);
        }
    }

    handlePreset = (value) => {
        var val = mask(value);

        var error = val.length < this.minLen;
        this.setState({
            ...this.state,
            val: val,
            error: (error)?this.state.error:false,
            errorText: (error)?'O telefone precisa ter no mínimo 10 dígitos.':'',
        });
        this.props.onChange(error? "" : val);
    };

    handleChange = e => {
        var val = mask(e.target.value);

        var error = val.length < this.minLen;
        this.setState({ 
            ...this.state,
            val: val,
            error: (error)?this.state.error:false,
            errorText: (error)?'O telefone precisa ter no mínimo 10 dígitos.':'',
        });
        this.props.onChange(error? "" : val);
    };

    handleBlur = e => {
        if(this.state.val.length < this.minLen){
            this.setState({
                ...this.state,
                error: true,
                errorText: "O telefone precisa ter no mínimo 10 dígitos."
            });
            this.props.onChange("");
        }
    };

    render() {
        return (
            <TextField
                name="telefone"
                margin="normal"
                placeholder="(00) 00000-0000"
                label="Qual o seu telefone?"
                fullWidth
                onChange={this.handleChange}
                value={this.state.val}
                maxLength='14'
                error={this.props.shouldValidate && this.state.error}
                helperText={this.props.shouldValidate && this.state.errorText}
                onBlur={this.handleBlur}
                type="tel"
                disabled={this.props.disabled}
            />
        )
    }
}

export default InputTel;