import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TabsSimulacao from './TabsSimulacao';
import DefaultContext from '../themes/DefaultContext';

import '../assets/css/Simulacao.css';

function Simulacao(props) {
	const context = React.useContext(DefaultContext);

	const validateTheme = () => {
		return context.theme.name === 'credies';
	};

	return (
		<section id="simulacao" name="simulacao" className="bloco-simulacao">
			<Container maxWidth="lg">
				<Grid container>
					<Grid item xs={12} sm={12} md={6}>
						<Grid item xs={12}>
							<h2 className="tit-ies">
								<span>
									Crédito <br />
									educacional <br />
									com juros zero <br />
									<span className="txt-primary">
										e sem fiador
									</span>
								</span>
							</h2>
						</Grid>
						{!validateTheme() &&
							<Grid item xs={12} className="no-desk">
								<p className="text-center subtit-ies">e o restante só depois que se formar</p>
							</Grid>
						}
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<Grid item xs={12}>
							<p className="tit-simulacao">Faça uma simulação</p>
						</Grid>
						<Grid item xs={12} className="container-simulacao">
							<TabsSimulacao />
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
}

export default Simulacao;
