import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinkSocialGA from './UI/LinkSocialGA';
import '../assets/css/BotoesSociais.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faComments } from '@fortawesome/free-solid-svg-icons';
import Link from '@material-ui/core/Link';

import ZendeskWidget from './ZendeskWidget';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    backgroundColor: '#F0F5FC',
    maxWidth: '140px',
  },
}));

export default function BotoesSociais() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [helper, setHelper] = React.useState(true);

  const handleClick = (event) => {
    setHelper(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setHelper(true);
    setAnchorEl(null);
  };

  const handleClickZendesk = () => {
    window.zE('messenger', 'open');
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="btn-group-social">
      <Button className="btn-social" aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        <FontAwesomeIcon size="3x" icon={faCommentDots} />
      </Button>
      <div className={helper ? 'helper' : 'hide-button'}>
        <span>
          Precisa de ajuda? <br />
          Chama aqui
        </span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Typography className={classes.typography}>
          <div className="title">
            <span>Por onde prefere atendimento?</span>
          </div>
          <div className="botoes-sociais">
            <div className="border-bottom">
              <Link id="zendesk" onClick={handleClickZendesk} className="botao-social-zd">
                <FontAwesomeIcon size="2x" className="max-width-height chat-icon" icon={faComments} /> Chat
              </Link>
            </div>
            <div>
              <LinkSocialGA
                href="https://api.whatsapp.com/send?phone=555140072302&text=Oi!%20Tenho%20interesse%20no%20Mais%20Acesso."
                target="_blank"
                id="wpp"
                onClick={handleClose}
              >
                <img src="./assets/img/botao-whatsapp.png" alt="Whatsapp" className="max-width-height" />{' '}
                <span>WhatsApp</span>
              </LinkSocialGA>
            </div>
          </div>
        </Typography>
      </Popover>

      {open && <ZendeskWidget zendeskKey="7ff98bf6-1bdb-44cd-9880-5cc4c85d5c28" />}
    </div>
  );
}
