import React,{useState} from 'react';
import { Container, CellButton, Button} from './styles';
import Mark from '../Mark';
import IESolicitarContatoModal from '../../modals/IESolicitarContatoModal';

const ColumnTop: React.FC = () =>{
    const [ modal, setModal] = useState(false);

    return(
        <Container>
                <Mark />
                <CellButton>
                    <Button type="button" onClick={(e) => setModal(true)} >QUERO CONTATO</Button>
                </CellButton>
                <IESolicitarContatoModal isOpen={modal} onClose={(e) => setModal(false)} />
        </Container>
    )
}

export default ColumnTop;