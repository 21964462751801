import React from 'react';

import { Container } from './styles';

interface TextCenterProps{
    text:string;
}
const TextCenter: React.FC<TextCenterProps> = ({text, ...rest}) =>{

    return(
        <Container>
            <p>{text}</p>
            {rest.children}
        </Container>
    )
}

export default TextCenter;