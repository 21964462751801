import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const InputEmail = (props) => {
    const [valid, setValid] = useState(false);
    const pattern = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

    const changeHandler = (event) => {
        const value = event.target.value;
        const isValid = value && pattern.test(value);
        setValid(isValid);
        props.onChange({ value: value, valid: isValid});
    }

    return (
        <TextField
            label="Qual o seu e-mail?"
            fullWidth
            type="email"
            name="email"
            error={ props.shouldValidate && !valid }
            helperText={ props.shouldValidate && !valid ? 'Insira o e-mail para simular' : ''}
            onChange={changeHandler}
            disabled={ props.disabled }
        />
    );
};

InputEmail.propTypes = {
    onChange: PropTypes.func,
    shouldValidate: PropTypes.bool,
    disabled: PropTypes.bool
};

export default InputEmail;