import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import '../assets/css/Sobre.css';

import useMediaQuery from '@material-ui/core/useMediaQuery';

function Sobre() {
	const isDesk = useMediaQuery('(min-width:960px)');

	return (
		<section id="quem_somos" className="bg-secondary sobre">
			<Container maxWidth="lg">
				<Grid container justify="center">
					<Grid container item xs={12} md={6} justify="center">
						<Grid item xs={12} md={10}>
							<h2>Sobre o Mais Acesso</h2>
						</Grid>

						<Grid item xs={12} md={8}>
							<p className="txt-sobre">
                                O Mais Acesso - uma solução da Fundacred - é uma forma rápida e sem burocracia de
                                estudar pagando apenas parte da mensalidade durante o curso e deixando o restante
                                do valor para depois da conclusão ou fim do vínculo com a instituição.
                                Com ele o estudante solicita mesmo sem fiador e solicita de forma 100% digital.
                                É bom para o aluno, que paga de forma mais flexível as mensalidades e bom para a
                                Instituição de Ensino, que garante a permanência do estudante no curso.</p>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
}

export default Sobre;