import React, { useEffect } from 'react';
import Header from '../Header';
import Simulacao from '../Simulacao';
import ComoFunciona from '../ComoFunciona';
import Compare from '../Compare';
import Sobre from '../Sobre';
import Onda from '../Onda';
import DefaultContext from '../../themes/DefaultContext';
import { Helmet } from 'react-helmet';
import EtapasSolicitacao from '../EtapasSolicitacao';
import { isEmpty, has } from 'lodash';
import queryString from 'query-string';
import Footer from '../Footer';
import BotoesSociais from '../BotoesSociais';
import LazyLoad from 'react-lazyload';
import { useContext } from 'react';

const isNotPrerender = () => {
  return navigator.userAgent !== 'ReactSnap';
};

function Home() {
  const { theme } = useContext(DefaultContext);

  useEffect(() => {
    const simParams = queryString.parse(window.location.search);
    if (!isEmpty(simParams) && has(simParams, 'pse') && has(simParams, 'curso')) {
      localStorage.setItem('parametrosSimulacao', JSON.stringify(simParams));
    }
  }, []);

  return (
    <>
      <section>
        <Helmet>
          <html data-theme={theme.name}></html>
          <title>{theme.displayName} - O melhor crédito educacional - Fundacred</title>
        </Helmet>
        <Header />
        <Simulacao />
        <Onda image="onda-1" />
        <Onda image="onda-3" />
        <ComoFunciona theme={theme} id="como_funciona" />
        <EtapasSolicitacao />
        {isNotPrerender() ? (
          <LazyLoad once offset={350}>
            <Compare />
          </LazyLoad>
        ) : (
          <div></div>
        )}
        <Sobre />

        <section className="onda-footer">
          <LazyLoad once offset={350}>
            <Onda image="onda-3" />
          </LazyLoad>
        </section>
      </section>
      <Footer />

      <BotoesSociais />

      {/* Necessário para pré-processamento de páginas extras */}
      <a href={process.env.PUBLIC_URL + '/convenio-ie'} style={{ display: 'none' }}>
        Convênio IE
      </a>
      <a href={process.env.PUBLIC_URL + '/frame-simulador'} style={{ display: 'none' }}>
        Frame Simulador
      </a>
    </>
  );
}

export default Home;
