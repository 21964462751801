/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import DropdownMobileDesktop from './DropdownMobileDesktop';
import * as simuladorService from '../../../utils/simulador-service';
import Alert from '@material-ui/lab/Alert'

const isNotPrerender = () => {
	return navigator.userAgent !== 'ReactSnap';
};

const CombosSimulacao = (props) => {
    const [estados, setEstados] = useState([]);
    const [instituicoes, setInstituicoes] = useState([]);
    const [allCampus, setAllCampus] = useState([]);
    const [cursos, setCursos] = useState([]);

    const [estado, setEstado] = useState(null);
    const [instituicao, setInstituicao] = useState(null);
    const [campus, setCampus] = useState(null);
    const [curso, setCurso] = useState(null);
    const [locationUf, setLocationUf] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getLocation);
            }
        }, 4000);
    }, []);

    useEffect(() => {
        if (props.paramsBolsa && estados.length > 0) {
            const estadosBolsa = estados.filter(item => props.paramsBolsa.estados.indexOf(item.value) >= 0);
            if (estadosBolsa.length > 0 && (estadosBolsa.length < estados.length)) {
                setEstados(estadosBolsa);
                if (estadosBolsa.length === 1) {
                    setEstado(estadosBolsa[0]);
                }
            }
        }
    }, [props.paramsBolsa, estados]);

    useEffect(() => {
        if (estado) {
            if (props.paramsBolsa) {
                const filteredIes = props.paramsBolsa.instituicoes
                    .filter(item => item.estado.includes(estado.value))
                    .filter(item => props.niveis.indexOf(item.tipo) >= 0);

                setInstituicoes(filteredIes);
                setInstituicao(filteredIes.length === 1 ? filteredIes[0] : null);

            } else {
                getInstituicoes(estado.value);
            }
        }
    }, [props.niveis, estado]);

    useEffect(() => {
        if(isNotPrerender()){
            setEstado(null);
            getEstados();
        }
    }, [props.niveis])

    useEffect(() => {
        if (!props.paramsBolsa && locationUf && estados.length > 0) {
            const estadoEncontrado = estados.find(item => item.label.localeCompare(locationUf) >= 0);
            setEstado(estadoEncontrado);
        }
    }, [estados, locationUf]);

    useEffect(() => {
        if (instituicao) {
            getCampus(instituicao.value, estado ? estado.value : instituicao.estado);
        } else {
            campusChangedHandler(null);
        }
    }, [instituicao]);

    useEffect(() => {
        if (curso) {
            selectionFinished();
        } else {
            props.onInvalid(true);
        }
    }, [curso]);

    const getLocation = async (obj) => {
        const response = await simuladorService.getLocation(obj);
        if (!locationUf) {
            const uf = response.data.address.state;
            setLocationUf(uf);
        }
    };

    const getEstados = async () => {
        simuladorService.getEstados(props.niveis).then(resp => {
            const filteredUfs = props.paramsBolsa ? resp.filter(item => props.paramsBolsa.estados.indexOf(item.value) >= 0) : resp;
            setEstados(filteredUfs);
        });
    }

    const getInstituicoes = async (estado) => {
        setInstituicao(null);
        simuladorService.getInstituicoes(estado, props.niveis).then(resp => setInstituicoes(resp));
    }

    const getCampus = async (instituicaoId, siglaUf) => {
        campusChangedHandler(null);

        const resp = await simuladorService.getCampus(instituicaoId, siglaUf);
        setAllCampus(resp);

        if (resp.length <= 1) {
            const firstCampus = resp[0] || null;
            campusChangedHandler(firstCampus);
        }
    }

    const getCursos = async (instituicaoId, codCampus = null) => {
        simuladorService.getCursos(instituicaoId, props.niveis, codCampus).then(resp => setCursos(resp));
    }

    const campusChangedHandler = (selected) => {
        const campusId = selected ? selected.value : null;
        setCampus(selected);
        setCurso(null);

        if (instituicao) {
            getCursos(instituicao.value, campusId);
        }
    }

    const selectionFinished = () => {
        props.onOptionsSelected({ estado, instituicao, campus, curso });
        props.onInvalid(false);
    }

    return (
        <>
            <FormGroup>
                <DropdownMobileDesktop
                    disabled={estados.length === 1 && estado}
                    label="Você vai estudar em qual estado?"
                    helperText="Digite ou selecione"
                    items={estados}
                    inputValue={estado ? estado.label : ''}
                    onChange={setEstado}
                    error={props.shouldValidate && !estado}
                    errorText="Selecione um estado" />
            </FormGroup>

            <FormGroup>
                <DropdownMobileDesktop
                    disabled={!estado || (instituicoes.length === 1 && instituicao)}
                    label="Em qual instituição?"
                    helperText="Digite ou selecione"
                    emptyText="Nenhuma instituição encontrada"
                    items={instituicoes}
                    inputValue={instituicao ? instituicao.label : ''}
                    onChange={setInstituicao}
                    error={props.shouldValidate && !instituicao}
                    errorText="Selecione uma instituição" />
            </FormGroup>

            {allCampus.length > 1 &&
                <FormGroup>
                    <DropdownMobileDesktop
                        disabled={!instituicao}
                        label="Em qual campus?"
                        helperText="Digite ou selecione"
                        emptyText="Nenhum campus encontrado"
                        items={allCampus}
                        inputValue={campus ? campus.label : ''}
                        onChange={campusChangedHandler}
                        error={props.shouldValidate && !campus}
                        errorText="Selecione um campus" />
                </FormGroup>
            }
            <FormGroup>
                <DropdownMobileDesktop
                    disabled={!instituicao || (allCampus.length > 1 && !campus)}
                    label="Qual o curso?"
                    helperText="Digite ou selecione"
                    emptyText="Nenhum curso encontrado"
                    items={cursos}
                    inputValue={curso ? curso.label : ''}
                    onChange={setCurso}
                    className="select-curso"
                    error={props.shouldValidate && !curso}
                    errorText="Selecione um curso" />
            </FormGroup>
            {curso && curso.oculto === 'S' &&
                <Alert severity="warning">
                    Este curso não está disponível para novas solicitações
            </Alert>
            }
        </>
    );
};

CombosSimulacao.propTypes = {
    niveis: PropTypes.array,
    paramsBolsa: PropTypes.object,
    shouldValidate: PropTypes.bool,
    onOptionsSelected: PropTypes.func,
    onInvalid: PropTypes.func
};

export default CombosSimulacao;