import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module';
import querystring from 'query-string';

import './assets/css/gotham.css';
import './App.css';
import { credies } from './themes';

import Home from './components/Pages/Home';
import Politica from './components/Pages/Politica';
import LandingIE from './components/Pages/ConvenioIE';
import DefaultContext from './themes/DefaultContext';
import FrameSimulador from 'components/Pages/FrameSimulador';

const isNotPrerender = () => {
	return navigator.userAgent !== "ReactSnap";
};

function App() {
	const queryParams = querystring.parse(window.location.search);

	useEffect(() => {
		if (isNotPrerender()) {
			setTimeout(() => {
				TagManager.initialize({ gtmId: 'GTM-5KQK9VQ' });
			}, 6000);
		}
	}, []);

	const redirectToWebapp = () => {
		window.location.href = `${process.env.REACT_APP_URL_WEBAPP}${queryParams.ie ? `?bolsa=${queryParams.ie}` : ''}`;
	};

	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<DefaultContext.Provider value={{ theme: credies, redirectToWebapp }}>
				<section>
					<Switch>
						<Route path="/frame-simulador" component={FrameSimulador} />
						<Route path="/politica-privacidade" component={Politica} />
						<Route path="/convenio-ie" component={LandingIE} />
						<Route path="/graduacao" component={Home} />
						<Route path="/ensino-tecnico" component={Home} />
						<Route path="/" exact component={Home} />
					</Switch>
				</section>
			</DefaultContext.Provider>
		</BrowserRouter>
	);
}

export default App;
