import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
    padding: 1rem 0 5rem 0;
    
    @media(max-width:900px){
        padding:1rem 0 1rem 0.3rem;
    }

    @media(max-width:600px){
        padding:0.1rem 0 1rem 0.3rem;
    }

    @media(max-width:320px){
        padding:0rem 0 1rem 0.3rem;
    }
`;

export const CellButton = styled.div`
    display:flex;
    justify-content:flex-end;
    
    @media(max-width:600px){
        margin-right:0.3rem;
        width:9.75rem;
        height:1.875;
    }
    @media(max-width:320px){
        margin-right:0;
        width:7.9rem;
        height:1.875;
    }

    
`;

export const Button = styled.button`
    width:100%;
    height:100%;   
    border: none;
    padding: 1rem 3.5rem;
    border-radius: 5rem;
    background:var( --color-roxo) ;
    color: var(--btnDefaultTextColor);
    font-family:'Gotham Bold';
    cursor:pointer;


    &:hover{
        cursor:pointer;
        background:var( --primaryTextColor) ;
    }

    &:focus{
        outline:thin dotted;
        outline:0px auto ;
        outline-offset:0;
    }
 @media(max-width:600px){
        height:1.875rem;
        font-size:0.75rem;
        padding:0;
    }

    @media(max-width:320px){
        height:1.575rem;
        font-size:0.6rem;
        padding:0;
    }
`; 



