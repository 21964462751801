import React from 'react';

import {Container, AccordionSection ,IconFaAngleDown} from './styles';

interface AccordionProps{
    title:string;
    text:string;
    id:string;
}

const Accordion: React.FC<AccordionProps> = ({id, title,text}) =>{
    return(
            <Container>
                <AccordionSection >
                        <input id={id} type="checkbox" />  
                        <label htmlFor={id} ><IconFaAngleDown rotate={360} />{title}</label>
                        <span>{text}</span>
                </AccordionSection>
            </Container>
    )
}
export default Accordion;
