const formatMoney = (value) => {
    if (!value) {
        return null;
    }
    if (typeof value == 'number') {
        value = value.toFixed(2).toString();
    }

    value = value.replace(/\D/g, '');
    if (value.length < 2) {
        value = value + '00';
    }
    return (parseFloat(value) / 100).toFixed(2).replace('.', ',').replace(/\d(?=(\d{3})+,)/g, '$&.');

};

export default formatMoney;