import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    align-items:center;
    >img{
    }
    @media(max-width:600px){
        >img{
            width:8rem;
        }    
    }
`;
